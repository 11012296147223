import { gql } from "@apollo/client";

export const user_login_query = gql`
  # Write your query or mutation here
  query login($username: String!, $password: String!) {
    user_login: login(username: $username, password: $password) {
      user_id
      name
      token
      status
      mobile
      role
      allowed_district
      error {
        status_code
        message
      }
      force_password_change
    }
  }
`;

export const force_password_change_query = gql`
  mutation ForcePasswordChange($password: String!) {
    force_password_change: ForcePasswordChange(password: $password) {
      id
      status
      error {
        message
      }
    }
  }
`;
