import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack, Pressable, Spacer } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/antd_modal/antd_modal";
import VForm from "@views/components/ui/antd_form";
import AntdDatePicker from "../ui/antd_datepicker/date_picker";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { getAllSocietyQuery, getDistrictsQuery } from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Button, DatePicker, Form, Pagination, Select, Table } from "antd";
import { showToast } from "@helpers/toast";
import SocietyDetails from "./society_details";
import SocietyDelete from "./society_delete";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import SearchBox from "../ui/search_box/search_box";
import Reports from "./reports";
import { MdBugReport, MdOutlineNoteAlt, MdSpeakerNotes } from "react-icons/md";
import { startCase } from "lodash";
import { useInsertionEffect } from "react";
import { retrieveItem } from "@helpers/storage";

const AdditionalDataSocietyList = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { file_type } = props;

  console.log("file_type", file_type);
  // const location = useLocation();

  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [dialog_visible, setDialogVisible] = useState(false);
  const [action_item, setActionItem] = useState({});
  const [action_type, setActionType] = useState(null);
  const [model_open, setModalOpen] = useState(false);
  const [report_modal_open, setReportModalOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [district_id, setDistrictId] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const location = useLocation();
  const { t } = useTranslation();
  let role = retrieveItem("role");

  const dashboard_status = location.state;

  const { error: createError, status: createStatus } =
    useDynamicSelector("create_society");
  const { error: updateError, status: updateStatus } =
    useDynamicSelector("update_society");

  const {
    items: societies,
    pagination,
    loading,
  } = useDynamicSelector("get_all_society");

  const { items: Districts } = useDynamicSelector("get_districts");
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_society");

  const handleDeleteModalOpen = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const handleDeleteModalClose = (item) => {
    setDialogVisible(false);
  };
  const dateFormat = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const milliseconds = date.getMilliseconds();

    const formattedDate =
      `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`.replace(
        "/",
        "-"
      );
    return formattedDate;
  };

  const getSocieties = (should_load) => {
    let keys = [{ key: "get_all_society", loading: should_load }];
    dispatch(
      dynamicRequest(keys, getAllSocietyQuery, {
        page_number: current,
        page_limit: current_limit,
        search_string: search,
        status: status,
        district_id: district_id,
        from_date: fromDate ? dateFormat(fromDate) : null,
        to_date: toDate ? dateFormat(toDate) : null,
      })
    );
  };
  const getDistrict = (should_load) => {
    let keys = [{ key: "get_districts", loading: should_load }];
    dispatch(dynamicRequest(keys, getDistrictsQuery));
  };

  useEffect(() => {
    if (location.search) {
      if (status) {
        getSocieties(true);
      }
    } else {
      getSocieties(true);
    }
  }, [
    status,
    district_id,
    toDate,
    fromDate,
    dashboard_status,
    location.search,
  ]);

  useEffect(() => {
    getDistrict();
  }, []);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
    form.resetFields();
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
    // form.resetFields();
    form.setFieldsValue(item);
  };

  const handleReport = (item) => {
    setActionItem(item);
    setReportModalOpen(true);
  };

  const handleReportClose = () => {
    setReportModalOpen(false);
  };

  useEffect(() => {
    if (action_type === "add" && createStatus === "Success") {
      handleModalClose();
      showToast({
        type: "success",
        message: `Society added successfully`,
      });
      getSocieties();
      dispatch(dynamicClear("create_society"));
    } else if (action_type === "add" && createError) {
      showToast({
        type: "error",
        message: `Something went wrong!`,
      });
      getSocieties();
      dispatch(dynamicClear("create_society"));
    } else if (action_type === "edit" && updateStatus === "Success") {
      handleModalClose();
      showToast({
        type: "success",
        message: `Society updated successfully`,
      });
      getSocieties();
      dispatch(dynamicClear("update_society"));
    } else if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `Society deleted successfully`,
      });
      dispatch(dynamicClear("delete_society"));
      getSocieties();
      handleDeleteModalClose();
    } else if (deleteError) {
      showToast({
        type: "error",
        message: `Something went wrong!`,
      });
      dispatch(dynamicClear("delete_society"));
      getSocieties();
      handleDeleteModalClose();
    }
  }, [
    createStatus,
    updateStatus,
    deleteStatus,
    updateError,
    createError,
    deleteError,
  ]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_society", loading: true }];
    dispatch(
      dynamicRequest(keys, getAllSocietyQuery, {
        page_number: page_number,
        page_limit: page_limit,
        status: status,
        search_string: search,
        district_id: district_id,
        from_date: fromDate ? dateFormat(fromDate) : null,
        to_date: toDate ? dateFormat(toDate) : null,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleView = (value) => {
    history.push({
      pathname: `${ROUTES.ADDITIONAL_FILE_UPLOAD}/${value?.id}`,
      state: [value],
    });
  };
  let param1;
  let param2;
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    param1 = queryParams.get("param1");
    param2 = queryParams.get("param2");
    setDistrictId(param2);
    setStatus(param1);
  }, [location.search]);

  const handleSearch = (search_txt) => {
    let keys = [{ key: "get_all_society", loading: true }];
    let variables = {
      search_string: search_txt,
      page_limit: current_limit,
      page_number: current,
      status: status,
      district_id: district_id,
      from_date: fromDate ? dateFormat(fromDate) : null,
      to_date: toDate ? dateFormat(toDate) : null,
    };
    dispatch(dynamicRequest(keys, getAllSocietyQuery, variables));
    setSearch(search_txt);
  };
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  const status_list = [
    {
      name: "Processing",
      value: "processing",
    },

    {
      name: "On-going",
      value: "current_processing",
    },
    // {
    //   name: "Invalid File",
    //   value: "invalid",
    // },
    {
      name: "Valid File",
      value: "valid",
    },
    {
      name: "Second Level Validated",
      value: "is_second_level_validated",
    },
  ];
  let columns = [
    {
      title: t("table:society"),
      // dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (society) => {
        return (
          <VStack>
            <Pressable
              onPress={() => {
                handleView(society);
              }}
            >
              <Text fontWeight={"bold"}>
                {society?.name ? society?.name : "-"}
              </Text>
              <Text>{society?.username ? society?.username : "-"}</Text>
            </Pressable>
          </VStack>
        );
      },
    },
    {
      title: t("table:district"),
      // dataIndex: "district.[name]",
      // key: "district.[name]",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <>
            <Pressable
              onPress={() => {
                handleView(record);
              }}
            >
              <Text>{record.district ? record?.district?.name : "-"}</Text>
            </Pressable>
          </>
        );
      },
    },
    {
      title: t("table:status"),
      dataIndex: "",
      key: "latest_excel",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        if (
          record?.latest_excel?.status === "Valid File" ||
          record?.latest_excel?.status === "Additional Data Error"
        ) {
          return (
            <Pressable
              onPress={() => {
                handleReport(record);
              }}
            >
              <Text color={"blue.600"}>{record?.latest_excel?.status}</Text>
            </Pressable>
          );
        } else {
          return record?.latest_excel?.status;
        }
      },
    },

    {
      title: t("table:second_level_validation"),
      dataIndex: "latest_excel",
      key: "count",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record
          ? record.total_user_excel_match_count !== null && (
              <Text
                color={
                  record.is_second_level_validated ? "green.700" : "red.600"
                }
              >{`${record?.total_user_excel_match_count} / ${record?.total_match_count}`}</Text>
            )
          : "-";
      },
    },
    {
      title: t("table:last_updated_time"),
      dataIndex: "latest_excel",
      key: "latest_excel",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleView(record?.id);
            }}
          >
            {record?.uploaded_time
              ? moment(new Date(Number(record?.uploaded_time)))
                  .utcOffset("+05:30")
                  .format("DD/MM/YY hh:mm a")
              : "-"}
          </Pressable>
        );
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      render: (record) => {
        return (
          <HStack space="5" justifyContent={"left"}>
            <Pressable
              onPress={() => {
                handleEdit(record);
              }}
            >
              <AiOutlineEdit color="#158d94" size="20" />
            </Pressable>
            {role === "Admin" && (
              <Pressable
                onPress={() => {
                  handleDeleteModalOpen(record);
                }}
              >
                <AiOutlineDelete color="#ef0303e0" size="20" />
              </Pressable>
            )}
          </HStack>
        );
      },
    },
  ];
  let column1 = [
    {
      title: t("table:society"),
      // dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (society) => {
        return (
          <VStack>
            <Pressable
              onPress={() => {
                handleView(society);
              }}
            >
              <Text fontWeight={"bold"}>
                {society?.name ? society?.name : "-"}
              </Text>
              <Text>{society?.username ? society?.username : "-"}</Text>
            </Pressable>
          </VStack>
        );
      },
    },
    {
      title: t("table:district"),
      // dataIndex: "district.[name]",
      // key: "district.[name]",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <>
            <Pressable
              onPress={() => {
                handleView(record);
              }}
            >
              <Text>{record.district ? record?.district?.name : "-"}</Text>
            </Pressable>
          </>
        );
      },
    },
    {
      title: t("table:status"),
      dataIndex: "",
      key: "latest_excel",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        console.log("status", record);
        if (
          record?.latest_excel?.status === "Valid File" ||
          record?.latest_excel?.status === "Additional Data Error"
        ) {
          return (
            <Pressable
              onPress={() => {
                handleReport(record);
              }}
            >
              <Text color={"blue.600"}>{record?.latest_excel?.status}</Text>
            </Pressable>
          );
        } else {
          return record?.latest_excel?.status;
        }
      },
    },
    {
      title: t("table:second_level_validation"),
      dataIndex: "latest_excel",
      key: "count",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record ? (
          <Text
            color={record.is_second_level_validated ? "green.700" : "red.600"}
          >{`${record?.total_user_excel_match_count} / ${record?.total_match_count}`}</Text>
        ) : (
          "-"
        );
      },
    },
    {
      title: t("table:last_updated_time"),
      dataIndex: "",
      key: "latest_excel",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleView(record);
            }}
          >
            {record?.latest_excel?.uploaded_time
              ? moment(new Date(Number(record?.latest_excel?.uploaded_time)))
                  .utcOffset("+05:30")
                  .format("DD/MM/YY hh:mm a")
              : "-"}
          </Pressable>
        );
      },
    },
  ];
  return (
    <>
      <Box width="100%" mt="10px">
        <VStack space={5}>
          <HStack space="5" mt={5}>
            <Box width="300px">
              <Text bold>Status</Text>
              <Select
                style={{ width: "100%" }}
                allowClear
                showSearch
                value={status}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => {
                  setStatus(e);
                }}
              >
                {status_list?.map((item) => {
                  return <Option value={item.value}>{item.name}</Option>;
                })}
              </Select>
            </Box>
            {role !== "district_admin" && (
              <Box width="300px">
                <Text bold>District</Text>
                <Select
                  style={{ width: "100%" }}
                  allowClear
                  showSearch
                  value={district_id}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    setDistrictId(value);
                  }}
                >
                  {Districts?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Box>
            )}
            <Box width="300px">
              <Text bold>From Date</Text>

              <DatePicker
                onChange={(a) => {
                  setFromDate(a?.$d);
                }}
                disabledDate={disabledDate}
              />
            </Box>
            <Box width="300px">
              <Text bold>To Date</Text>

              <DatePicker
                onChange={(a) => {
                  setToDate(a?.$d);
                }}
                disabledDate={disabledDate}
              />
            </Box>
          </HStack>
          <HStack space={3} justifyContent="flex-end">
            {(role === "Admin" ||
              role === "district_admin" ||
              role === "Zone Admin") && (
              <Box key={`header-action`} alignSelf={"center"}>
                <Button
                  onClick={handleAdd}
                  color="#158d94"
                  style={{
                    height: "50px",
                  }}
                >
                  {t("add_society")}
                </Button>
              </Box>
            )}
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              columns={
                role === "Admin" ||
                role === "district_admin" ||
                role === "Zone Admin"
                  ? columns
                  : column1
              }
              dataSource={societies}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={fetchData}
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={model_open}
        onClose={handleModalClose}
        width={450}
        footer={null}
        header={action_type === "add" ? t("add_society") : t("edit_society")}
        component={
          <SocietyDetails
            label={action_type === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={action_item}
          />
        }
      />
      <AntdModal
        isOpen={dialog_visible}
        onClose={handleDeleteModalClose}
        width={450}
        footer={null}
        header={t("delete_society")}
        component={
          <SocietyDelete
            close={handleModalClose}
            initialValues={action_item}
            form={form}
          />
        }
      />
      <AntdModal
        isOpen={report_modal_open}
        onClose={handleReportClose}
        width={1000}
        footer={null}
        // header={t("Reports")}
        component={
          <Reports
            close={handleReportClose}
            initialValues={action_item}
            is_society_report={true}
          />
        }
      />
    </>
  );
};
export default AdditionalDataSocietyList;
