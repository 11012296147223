import { useState, useEffect } from "react";
import { retrieveItem } from "./storage";

const useCheckLogin = () => {
  const [is_logged_in, setIsLoggedIn] = useState(null);
  useEffect(() => {
    let token = retrieveItem("token");
    let force_password_change = retrieveItem("force_password_change");
    try {
      if (token && force_password_change) {
        setIsLoggedIn(false);
      } else if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (e) {
      setIsLoggedIn(false);
    }
  });
  return is_logged_in;
};

const usePageAccess = (key) => {
  try {
    return true;

    let roles = retrieveItem("roles");
    let pages = [];
    for (let i = 0; i < roles.length; i++) {
      let role = roles[i];
      if (role === "Employee") {
        pages.push("dashboard", "log_timesheet", "apply_leave");
      }
      if (role === "Manager") {
        pages.push(
          "approve_timesheet",
          "report_total_hours",
          "report_current_sprint",
          "report_by_epic"
        );
      }
      if (role === "HR") {
        pages.push("attendance_report");
      }
      if (role === "Admin") {
        pages.push("approve_leave");
      }
      if (role === "Admin") {
        pages.push("users", "projects", "tasks", "userWorkItem");
      }
    }
    pages = [...new Set(pages)];
    let pageAccess = pages.find((x) => x === key);
    if (pageAccess) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
  // useEffect(() => {
  //   let user = null;
  //   let userValue = localStorage.getItem("user");
  //   try {
  //     user = JSON.parse(userValue);
  //     let pageAccess = user.PageAccess.find((x) => x.Name === key);
  //     if (pageAccess) {
  //       setHasAccess(true);
  //     } else {
  //       setHasAccess(false);
  //     }
  //   } catch (e) {
  //     setHasAccess(false);
  //   }
  // });
};

const usePageComponentAccess = (key) => {
  const [hasAccess, setHasAccess] = useState(null);
  useEffect(() => {
    let user = null;
    let userValue = localStorage.getItem("user");
    try {
      user = JSON.parse(userValue);
      let pageComponentAccess = user.PageComponentAccess.find(
        (x) => x.Name === key
      );
      if (pageComponentAccess) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
    } catch (e) {
      setHasAccess(false);
    }
  });
  return hasAccess;
};

export {
  useCheckLogin as useCheckLogin,
  usePageAccess as usePageAccess,
  usePageComponentAccess as usePageComponentAccess,
};
