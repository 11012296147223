import { gql } from "@apollo/client";

export const process_excel_mutation = gql`
  mutation excel_import($file: String!) {
    process_excel: excel_import(file: $file) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const user_excel_list_query = gql`
  query getUserExcels(
    $search_string: String
    $user_id: String
    $status: String
    $page_limit: Int
    $page_number: Int
  ) {
    user_excel_list: getUserExcels(
      search_string: $search_string
      user_id: $user_id
      status: $status
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        excel_url
        uploaded_time
        is_sheet_processing
        is_sheet_valid
        is_column_processing
        is_column_valid
        is_data_processing
        is_valid
        is_second_level_validated
        is_third_level_validated
        secondary_level_validated
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const user_excel_query = gql`
  query getUserExcel($id: String) {
    user_excel: getUserExcel(id: $id) {
      id
      excel_url
      uploaded_time
      errors
      is_sheet_processing
      is_sheet_valid
      is_column_processing
      is_column_valid
      is_data_processing
      total_user_excel_match_count
      total_match_count
      is_second_level_validated
      is_third_level_validated
      is_valid
      status
      secondary_level_validated
      secondary_level_validation
      response_id
      live_response_id
      error {
        message
      }
    }
  }
`;

export const third_level_validation = gql`
  query getUserExcel($id: String) {
    user_excel: getUserExcel(id: $id) {
      id
      excel_url
      uploaded_time
      third_level_validation
      is_sheet_processing
      is_sheet_valid
      is_column_processing
      is_column_valid
      secondary_level_validation
      is_data_processing
      is_valid
      errors
      error {
        status_code
        message
      }
    }
  }
`;

export const secondary_level_validation = gql`
  query getUserExcel($id: String) {
    user_excel: getUserExcel(id: $id) {
      id
      excel_url
      uploaded_time
      errors
      is_sheet_processing
      is_sheet_valid
      is_column_processing
      is_column_valid
      secondary_level_validation
      is_data_processing
      is_valid
      error {
        status_code
        message
      }
    }
  }
`;
export const bridge_mutation = gql`
  mutation pushToBridge($id: String) {
    pushToBridge(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const cbs_mutation = gql`
  mutation dataMigrate($society_id: String) {
    dataMigrate(society_id: $society_id) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;

export const get_user_latest_excel_query = gql`
  query getUserLatestExcel {
    getUserLatestExcel {
      id
      excel_url
      error {
        status_code
        message
      }
    }
  }
`;

export const get_push_to_bridge_response_query = gql`
  query getPushToBridgeResponse($id: String) {
    getPushToBridgeResponse(id: $id) {
      id
      url
      logs
      status
      errors
    }
  }
`;

export const push_to_cbs_mutation = gql`
  mutation pushToCBS($id: String) {
    pushToCBS(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_CBS_migration_logs_query = gql`
  query getCBSMigrationLogs($id: String) {
    getCBSMigrationLogs(id: $id) {
      id
      society_id
      logs
    }
  }
`;

export const delete_one_society_from_bridge_mutation = gql`
  mutation deleteOneSocietyFromBridge($id: String) {
    deleteOneSocietyFromBridge(id: $id) {
      status
      url
      error {
        message
      }
    }
  }
`;

export const delete_one_society_from_CBS_mutation = gql`
  mutation deleteOneSocietyDataFromCBS($id: String) {
    deleteOneSocietyDataFromCBS(id: $id) {
      status
      url
      error {
        message
      }
    }
  }
`;

export const get_user_excel_progress_query = gql`
  query getUserExcelProgress($excel_id: String!) {
    getUserExcelProgress(excel_id: $excel_id) {
      id
      excel_id
      status
      is_completed
      error {
        message
      }
    }
  }
`;

export const push_to_bridge_live_mutation = gql`
  mutation pushToBridgeLive($id: String) {
    pushToBridgeLive(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_push_to_bridge_live_response_query = gql`
  query getPushToBridgeLiveResponse($id: String) {
    getPushToBridgeLiveResponse(id: $id) {
      id
      url
      logs
      status
      errors
    }
  }
`;

export const push_to_CBS_live_mutation = gql`
  mutation pushToCBSLive($id: String) {
    pushToCBSLive(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_CBS_live_migration_response_logs_query = gql`
  query getCBSLiveMigrationLogs($id: String) {
    getCBSLiveMigrationLogs(id: $id) {
      id
      society_id
      logs
    }
  }
`;

export const get_live_credential_query = gql`
  query getLiveCredential($society_id: String!) {
    getLiveCredential(society_id: $society_id) {
      username
      password
    }
  }
`;
