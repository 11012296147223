import SecureLS from "secure-ls";

const secretKey = "P@ssKey!@#$";

export function storeItem(key, data) {
  try {
    new SecureLS({ encodingType: "aes", encryptionSecret: secretKey }).set(
      key,
      data
    );
  } catch (e) {
    return e;
  }
}

export function retrieveItem(key) {
  try {
    return new SecureLS({
      encodingType: "aes",
      encryptionSecret: secretKey,
    }).get(key);
  } catch (e) {
    return e;
  }
}

export function clearLocalStorage() {
  try {
    window.localStorage.clear();
  } catch (e) {
    return;
  }
}
