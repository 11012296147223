import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Center, HStack, Text } from "native-base";
import toast from "react-hot-toast";
import {
  dynamicClear,
  dynamicRequest,
  get_user_excel_progress_query,
  process_excel_mutation,
  useDynamicSelector,
} from "@services/redux";
import FileUploadModalStatus from "./file_upload_modal_status";

const FileUploadDetails = (props) => {
  const { t } = useTranslation();
  const { form, close, getUserExcels, set_is_modal_open } = props;
  const dispatch = useDispatch();
  const [process_excel_id, set_process_excel_id] = useState("");
  const { status, error, loading, id } = useDynamicSelector("process_excel");

  const {
    status: user_excel_process_status,
    error: user_excel_process_error,
    is_completed,
  } = useDynamicSelector("getUserExcelProgress");

  useEffect(() => {
    if (process_excel_id) {
      getUserExcelProgressStatus();
      setTimeout(() => {
        set_is_modal_open(true);
      }, 1000);
    }
  }, [process_excel_id]);

  useEffect(() => {
    if (is_completed || user_excel_process_status === "completed") {
      set_process_excel_id("");
      set_is_modal_open(false);
      getUserExcels(1, 10);
    } else if (
      is_completed === false ||
      !user_excel_process_status === "completed"
    ) {
      set_is_modal_open(true);
      setTimeout(() => {
        getUserExcelProgressStatus();
      }, 5000);
    }
  }, [is_completed, user_excel_process_status]);

  useEffect(() => {
    if (status === "Success") {
      set_process_excel_id(id);
      toast.success("Excel processing started please check after some time");
      close();
      getUserExcels(1, 10);
      dispatch(dynamicClear("process_excel"));
    } else if (error?.message) {
      dispatch(dynamicClear("process_excel"));
      close();
      toast.error("Something went wrong please try again");
    }
  }, [error, status]);

  const handleSubmit = (values) => {
    let process_excel_key = [{ key: "process_excel", loading: true }];
    let process_excel_variables = {
      file: values.file[0].url,
      password: values.password,
    };

    dispatch(
      dynamicRequest(
        process_excel_key,
        process_excel_mutation,
        process_excel_variables
      )
    );
  };

  const getUserExcelProgressStatus = () => {
    let keys = [{ key: "getUserExcelProgress", loading: true }];
    let variable = {
      excel_id: process_excel_id,
    };
    dispatch(dynamicRequest(keys, get_user_excel_progress_query, variable));
  };

  return (
    <Box flex={1}>
      <Form
        form={form}
        name={"file_upload"}
        layout={"vertical"}
        onFinish={handleSubmit}
      >
        <Box flex={1} mt="6">
          <VForm.File
            label={t("form:file")}
            field="file"
            rules={[
              {
                required: true,
                message: t("error:upload_file_required"),
              },
            ]}
          />
        </Box>

        <HStack space="10" justifyContent={"flex-end"}>
          <VForm.Button isLoading={loading}>{t("Submit")}</VForm.Button>
          <Button onClick={close}>{t("close")}</Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default FileUploadDetails;
