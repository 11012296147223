import { Text } from "native-base";

export const ProjectName = "ECS Data Migration";

export const amount_format = (value) => {
  return value.toLocaleString("en-IN");
};

export const amountFormat = (value) => {
  if (typeof value === "number")
    return (
      <Text textAlign="right">{`${value
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })
        .replace("₹", "")}`}</Text>
    );
  else return <Text textAlign="right">{"0"}</Text>;
};

export const amountFormatWithSymbol = (value) => {
  if (typeof value === "number")
    return (
      <Text textAlign="right">{`${value.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })}`}</Text>
    );
  else return <Text textAlign="right">{"0"}</Text>;
};
export const amountFormatWithSymbolAndColor = (value) => {
  if (typeof value === "number")
    return (
      <Text color={"blue.600"}>{`${value.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })}`}</Text>
    );
  else return <Text>{"0"}</Text>;
};
