import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "@services/redux";
import { Button } from "antd";
import { Box } from "native-base";
import { capitalize } from "lodash";

const BridgePushResponseDetails = () => {
  const { t } = useTranslation();
  const response_list = useDynamicSelector("getPushToBridgeResponse");

  const [response, set_response] = useState(null);
  const [response_error, set_response_error] = useState(null);

  useEffect(() => {
    if (response_list?.logs) {
      const parsedLogs = JSON.parse(response_list?.logs);
      set_response(parsedLogs);
    }
    if (response_list?.errors) {
      const parsedErrors = JSON.parse(response_list?.errors);
      set_response_error(parsedErrors);
    }
  }, [response_list]);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          padding: "5px",
          marginBottom: "10px",
        }}
      >
        <text style={{ fontWeight: "bold" }}>
          {response_list?.status &&
            capitalize(response_list?.status.replace(/_/g, " "))}
        </text>
      </Box>
      {response && (
        <Box
          style={{
            maxHeight: "380px",
            overflowY: "auto",
          }}
        >
          {response?.map((log, index) => (
            <Box
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "10px",
              }}
            >
              <div style={{ marginBottom: "5px" }}>
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Table:{" "}
                </span>
                <span style={{ fontSize: "11px" }}>
                  {capitalize(log.table.replace(/_/g, " "))}
                </span>
              </div>
              <div>
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Count:{" "}
                </span>
                <span style={{ fontSize: "11px" }}>{log?.response?.count}</span>
                <hr style={{ border: "1px solid lightgray" }} />
              </div>
            </Box>
          ))}
        </Box>
      )}
      {response_error && (
        <Box
          style={{
            maxHeight: "380px",
            overflowY: "auto",
          }}
        >
          {response_error?.map((error, index) => (
            <Box
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "10px",
              }}
            >
              <div style={{ marginBottom: "3px" }}>
                <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                  Message:{" "}
                </span>
                <span style={{ fontSize: "10px" }}>{error.message}</span>
              </div>
              <div style={{ marginBottom: "3px" }}>
                <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                  Error Type:{" "}
                </span>
                <span style={{ fontSize: "10px" }}>{error.error_type}</span>
              </div>
              <div style={{ marginBottom: "3px" }}>
                <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                  Status:{" "}
                </span>
                <span style={{ fontSize: "10px" }}>
                  {capitalize(error?.status?.replace(/_/g, " "))}
                </span>
              </div>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default BridgePushResponseDetails;
