import { amountFormatWithSymbolAndColor } from "@helpers/constants";
import { Box, Divider, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const RDDepositDetails = (props) => {
  const { rd_deposit } = props;
  const { t } = useTranslation();

  return (
    <>
      <>
        <Box marginTop={"10px"}>
          <Text bold fontSize="16px">
            RD Deposit
          </Text>
        </Box>
        <table border>
          <thead>
            <tr>
              <th>{t("loan_product")}</th>
              <th>{t("gl_amount")}</th>
              <th>{t("breakup_total_amount")}</th>
              <th>{t("result")}</th>
            </tr>
          </thead>
          <tbody>
            {rd_deposit?.map((x) => {
              return (
                <tr>
                  <td>{x.code}</td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormatWithSymbolAndColor(x?.deposit_gl_amount)}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormatWithSymbolAndColor(x?.deposit_outstanding_sum)}
                  </td>
                  <td>
                    <Text
                      color={
                        x?.amount_match_msg
                          ?.toLowerCase()
                          .includes("mismatched")
                          ? "red.500"
                          : "green.600"
                      }
                    >
                      {x?.amount_match_msg}
                    </Text>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Box>
          {rd_deposit?.map((x) => {
            if (x.amount_match_msg?.toLowerCase().includes("mismatched")) {
              return (
                <Text>
                  The total of respective amounts in{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    "RD Deposit Amount (in Rs.)"
                  </span>{" "}
                  column in the{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    "{x.code}"
                  </span>{" "}
                  sheet must match with{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    "{x.principal_payment_gl_name}"
                  </span>{" "}
                  value in the{" "}
                  <span style={{ fontWeight: "bold" }}>"GL Account"</span> sheet{" "}
                </Text>
              );
            }
            return null;
          })}
        </Box>
        <Box>
          {rd_deposit?.some(
            (x) =>
              x.principal_payment_gl_required_type ||
              x.interest_payment_gl_required_type
          ) && (
            <table bordered>
              <thead>
                <tr>
                  <th>{t("gl_for")}</th>
                  <th>{t("expected_gl_type")}</th>
                  <th>{t("given_gl_type")}</th>
                  <th>{t("result")}</th>
                </tr>
              </thead>
              <tbody>
                {rd_deposit?.map((x, index) => {
                  if (
                    x.principal_payment_gl_required_type ||
                    x.interest_payment_gl_required_type
                  ) {
                    return (
                      <>
                        {x.principal_payment_gl_required_type && (
                          <tr>
                            <td>{"Principal"}</td>

                            <td>{x.principal_payment_gl_required_type}</td>
                            <td>{x.principal_payment_gl_type}</td>
                            <td>
                              <Text
                                color={
                                  x.principal_payment_gl_match
                                    ?.toLowerCase()
                                    .includes("mismatched")
                                    ? "red.500"
                                    : "green.600"
                                }
                              >
                                {x.principal_payment_gl_match}
                              </Text>
                            </td>
                          </tr>
                        )}
                        {x.interest_payment_gl_required_type && (
                          <tr>
                            <td>{"Interest"}</td>

                            <td>{x.interest_payment_gl_required_type}</td>
                            <td>{x.interest_payment_gl_type}</td>
                            <td>
                              <Text
                                color={
                                  x.interest_payment_gl_match
                                    ?.toLowerCase()
                                    .includes("mismatched")
                                    ? "red.500"
                                    : "green.600"
                                }
                              >
                                {x.interest_payment_gl_match}
                              </Text>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
          )}
        </Box>
        <Box>
          {rd_deposit?.map((x) => {
            if (
              x.principal_payment_gl_type_match
                ?.toLowerCase()
                .includes("mismatched")
            ) {
              return (
                <>
                  <Text>
                    Expected type of{" "}
                    {/* <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "GL Account" {""}
                        </span>{" "} */}
                    GL Account{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      "{x.principal_payment_gl_name}"
                    </span>{" "}
                    in the{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      "RD Deposit Products"
                    </span>{" "}
                    sheet should be{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      "{x.principal_payment_gl_required_type}"
                    </span>{" "}
                    but found{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      "{x.principal_payment_gl_type}"
                    </span>
                  </Text>
                </>
              );
            }
            return null;
          })}
        </Box>

        <Box>
          {rd_deposit?.map((x) => {
            if (
              x.interest_payment_gl_type_match
                ?.toLowerCase()
                .includes("mismatched")
            ) {
              return (
                <>
                  <Text>
                    Expected type of{" "}
                    {/* <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          "Interest"
                        </span>{" "} */}
                    GL Account{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      "{x.interest_payment_gl_name}"
                    </span>{" "}
                    in the{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      "RD Deposit Products"{" "}
                    </span>{" "}
                    sheet should be{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      "{x.interest_payment_gl_required_type}"
                    </span>{" "}
                    but found{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      "{x.interest_payment_gl_type}"
                    </span>
                  </Text>
                </>
              );
            }
            return null;
          })}
        </Box>
        <Divider />
      </>
    </>
  );
};
export default RDDepositDetails;
