import {
  bridge_mutation,
  dynamicRequest,
  get_one_society_query,
  useDynamicSelector,
  get_additional_excel_query,
} from "@services/redux";
import { Button, Card } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import LazyLoad from "react-lazy-load";
import { retrieveItem } from "@helpers/storage";

const AdditionalFileUploadResponseDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = retrieveItem("role");
  const { id, society_id } = useParams();
  const [errors, setErrors] = useState([]);
  const [error_count, setErrorCount] = useState(0);
  const [status, setStatus] = useState("");

  const data = useDynamicSelector("getAdditionalExcel");
  const { loading } = useDynamicSelector("push_to_bridge");
  const { loading: cbs_loading } = useDynamicSelector("dataMigrate");
  const { name, username } = useDynamicSelector("getSociety");

  useEffect(() => {
    getUserExcel(id);
  }, [id]);

  useEffect(() => {
    getSociety();
  }, [society_id]);
  const getSociety = () => {
    let society_key = [{ key: "getSociety", loading: true }];
    let society_variables = {
      id: society_id,
    };
    dispatch(
      dynamicRequest(society_key, get_one_society_query, society_variables)
    );
  };
  useEffect(() => {
    if (data?.id) {
      let status = renderStatus(data);
      setStatus(status);
      let temp = [];
      if (data.errors) {
        temp = JSON.parse(data.errors);
        setErrorCount(temp?.length);
        if (status === "Data Error") {
          const groupedData = temp?.reduce((acc, entry) => {
            const { sheet_name, row_number } = entry;
            if (!acc[sheet_name]) {
              acc[sheet_name] = {};
            }
            if (!acc[sheet_name][row_number]) {
              acc[sheet_name][row_number] = [];
            }
            acc[sheet_name][row_number].push(entry);
            return acc;
          }, {});
          setErrors(groupedData);
        } else if (status === "Column Error") {
          const groupedData = temp?.reduce((acc, entry) => {
            const { sheet_name } = entry;
            if (!acc[sheet_name]) {
              acc[sheet_name] = [];
            }
            acc[sheet_name].push(entry);
            return acc;
          }, {});

          setErrors(groupedData);
        } else if (status === "Sheet Error" && temp?.length) {
          setErrors(temp);
        } else if (status === "Sheet Error") {
          setErrors([]);
        }
      }
    }
  }, [data]);

  const getUserExcel = (id) => {
    let user_excel_key = [{ key: "getAdditionalExcel", loading: true }];
    let user_excel_variables = {
      id,
    };
    dispatch(
      dynamicRequest(
        user_excel_key,
        get_additional_excel_query,
        user_excel_variables
      )
    );
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleBridgePush = () => {
    let bridge_key = [{ key: "push_to_bridge", loading: true }];
    let bridge_variables = {
      id,
    };
    dispatch(
      dynamicRequest(bridge_key, bridge_mutation, bridge_variables, "M")
    );
  };

  const handleCBSPush = () => {
    let cbs_key = [{ key: "dataMigrate", loading: true }];
    let cbs_variables = {
      society_id: id,
    };
    dispatch(dynamicRequest(cbs_key, cbs_mutation, cbs_variables, "M"));
  };

  const renderStatus = ({
    is_sheet_processing,
    is_column_processing,
    is_data_processing,
    is_sheet_valid,
    is_column_valid,
    is_valid,
  }) => {
    if (is_sheet_processing || is_column_processing || is_data_processing)
      return "Processing";
    if (is_sheet_valid && is_column_valid && is_valid) return "Valid File";
    if (is_sheet_valid && is_column_valid && !is_valid) return "Data Error";
    if (is_sheet_valid && !is_column_valid && !is_valid) return "Column Error";
    if (!is_sheet_valid && !is_column_valid && !is_valid) return "Sheet Error";
    return "Processing";
  };

  const renderError = (
    // {
    //   is_sheet_processing,
    //   is_column_processing,
    //   is_data_processing,
    //   is_sheet_valid,
    //   is_column_valid,
    //   is_valid,
    // },
    record
  ) => {
    switch (record) {
      case "data_missing_error":
        return "Data missing error";
      case "column_error":
        return "Column error";
      case "sheet_error":
        return "Sheet error";
      default:
        return record;
    }
  };
  return (
    <Box flex={1} padding="5">
      {data.loading ? (
        <Loader
          type="spinner-default"
          bgColor={"#349481"}
          color={"#349481"}
          size={50}
        />
      ) : (
        <Box>
          <HStack justifyContent="flex-end">
            <Button type="default" danger onClick={handleBack}>
              Back
            </Button>
          </HStack>
          {role !== "Society" && (
            <Box top="10px" zIndex="2" mb="10px">
              <Text fontSize="md" fontWeight="bold">
                {name} - {username}
              </Text>
            </Box>
          )}
          <Text bold fontSize={"md"}>
            Response
          </Text>
          <Box marginTop="2">
            <Card
              style={{
                alignItems: "center",
              }}
            >
              <HStack flex="1">
                <Box flex="1">
                  <VStack space={"5"}>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:uploaded_date_time")} : </Text>
                      </Box>
                      <Box>
                        <Text>
                          {data?.uploaded_time
                            ? moment(new Date(Number(data?.uploaded_time)))
                                .utcOffset("+05:30")
                                .format("DD/MM/YY hh:mm:ss a")
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:status")} : </Text>
                      </Box>
                      <Box>
                        <Text>{status} </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:error_count")} : </Text>
                      </Box>
                      <Box>
                        <Text>{error_count} </Text>
                      </Box>
                    </HStack>
                  </VStack>
                </Box>
                <Box flex="1">
                  <Center my={"auto"}>
                    {status === "Valid File" && role === "Admin" ? (
                      <HStack space={"8"}>
                        <Button
                          type="primary"
                          onClick={handleBridgePush}
                          loading={loading}
                        >
                          Push to Bridge
                        </Button>
                        <Button
                          type="primary"
                          onClick={handleCBSPush}
                          loading={cbs_loading}
                        >
                          Push to CBS
                        </Button>
                      </HStack>
                    ) : (
                      ""
                    )}
                  </Center>
                </Box>
              </HStack>
            </Card>
          </Box>

          <VStack>
            {status === "Sheet Error"
              ? errors?.map((x, i) => (
                  <LazyLoad offset={1200} key={i}>
                    <div key={i}>
                      <div className="sticky-name" style={{ marginTop: "5" }}>
                        <Center>
                          <Text bold>{x?.sheet_name} </Text>
                        </Center>
                        <Center>
                          {x?.row_number > 0 && (
                            <Text bold>Row Number: {x?.row_number} </Text>
                          )}
                        </Center>
                      </div>
                      <Box padding="3%">
                        <HStack>
                          <Box width="30%">
                            <Text bold>{t("table:error_type")} : </Text>
                          </Box>
                          <Box>
                            <Text>{renderError(x?.error_type)} </Text>
                          </Box>
                        </HStack>

                        {x?.is_data_error || x?.is_column_error ? (
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:column_name")} : </Text>
                            </Box>
                            <Box>
                              <Text>{x?.column_name} </Text>
                            </Box>
                          </HStack>
                        ) : (
                          ""
                        )}
                        <HStack>
                          <Box width="30%">
                            <Text bold>{t("table:error_message")} : </Text>
                          </Box>
                          <Box flex={1}>
                            {/* <Text>{renderStatus(x?.error)}</Text> */}
                            <Text>{x?.error}</Text>
                          </Box>
                        </HStack>
                        {x?.cells && (
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:missing_data")} : </Text>
                            </Box>
                            <Box flex={1}>
                              <Text>{x?.cells.join(", ")}</Text>
                            </Box>
                          </HStack>
                        )}
                      </Box>
                    </div>
                  </LazyLoad>
                ))
              : status === "Data Error"
              ? Object.entries(errors).map(([sheet_name, rows]) => (
                  <div key={sheet_name}>
                    {Object.entries(rows).map(([row_number, errors]) => (
                      <div key={row_number}>
                        <div className="sticky-name">
                          <Center>
                            <Text bold>{sheet_name} </Text>
                          </Center>
                          <Center>
                            {row_number > 0 && (
                              <Text bold>Row Number: {row_number}</Text>
                            )}
                          </Center>
                        </div>
                        {errors?.map((err, i) => (
                          <LazyLoad height={100} offset={1200} key={i}>
                            <div style={{ marginTop: "20px" }}>
                              <HStack>
                                <Box width="30%">
                                  <Text bold>{t("table:column_name")} : </Text>
                                </Box>
                                <Box>
                                  <Text>{err?.column_name} </Text>
                                </Box>
                              </HStack>
                              <HStack>
                                <Box width="30%">
                                  <Text bold>{t("table:error_type")} : </Text>
                                </Box>
                                <Box>
                                  <Text>{renderError(err?.error_type)} </Text>
                                </Box>
                              </HStack>

                              <HStack>
                                <Box width="30%">
                                  <Text bold>{t("table:error_message")} :</Text>
                                </Box>
                                <Box flex={1}>
                                  <Text>{err?.error}</Text>
                                </Box>
                              </HStack>
                              <HStack>
                                <Box width="30%">
                                  <Text bold>{t("table:missing_data")} : </Text>
                                </Box>
                                <Box flex={1}>
                                  <Text>
                                    <Text>{x?.cells?.join(",")}</Text>
                                  </Text>
                                </Box>
                              </HStack>
                            </div>
                          </LazyLoad>
                        ))}
                      </div>
                    ))}
                  </div>
                ))
              : status === "Column Error"
              ? Object.entries(errors).map(([sheet_name, errors]) => (
                  <div key={sheet_name}>
                    <div className="sticky-name">
                      <Center>
                        <Text bold>{sheet_name} </Text>
                      </Center>
                    </div>
                    {errors.map((err, i) => (
                      <LazyLoad height={100} offset={1200} key={i}>
                        <div>
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:column_name")} : </Text>
                            </Box>
                            <Box>
                              <Text>{err?.column_name} </Text>
                            </Box>
                          </HStack>
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:error_type")} : </Text>
                            </Box>
                            <Box>
                              <Text>{renderError(err?.error_type)} </Text>
                            </Box>
                          </HStack>

                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:error_message")} :</Text>
                            </Box>
                            <Box>
                              <Text>{err?.error}</Text>
                            </Box>
                          </HStack>
                          <HStack>
                            <Box width="30%">
                              <Text bold>{t("table:missing_data")} : </Text>
                            </Box>
                            <Box flex={1}>
                              <Text>{x?.cells?.join(",")}</Text>
                            </Box>
                          </HStack>
                        </div>
                      </LazyLoad>
                    ))}
                  </div>
                ))
              : ""}
          </VStack>
        </Box>
      )}
    </Box>
  );
};
export default AdditionalFileUploadResponseDetails;
