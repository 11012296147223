import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { HStack } from "native-base";
import {
  dynamicRequest,
  getDistrictsQuery,
  useDynamicSelector,
} from "@services/redux";
import { updateSocietyMutation, createSocietyMutation } from "@services/redux";
import { Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { retrieveItem } from "@helpers/storage";

const SocietyDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let role = retrieveItem("role");
  let user = retrieveItem("user");
  const { initialValues } = props;

  const { loading: createLoading } = useDynamicSelector("create_society");
  const { loading: updateLoading } = useDynamicSelector("update_society");
  const { items: get_districts } = useDynamicSelector("get_districts");
  const assign_initialValues = {
    name: initialValues?.name,
    username: initialValues?.username,
    district_id: initialValues?.district?.id,
  };
  const handleSubmit = (values) => {
    values.district_id =
      role === "Admin" ? values.district_id : user.allowed_district;
    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: values,
      };
      let keys = [{ key: "update_society", loading: true }];
      dispatch(dynamicRequest(keys, updateSocietyMutation, variables, "M"));
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "create_society", loading: true }];
      dispatch(dynamicRequest(keys, createSocietyMutation, variables, "M"));
    }
  };
  const get_districts_list = () => {
    let keys = [{ key: "get_districts", loading: true }];
    dispatch(dynamicRequest(keys, getDistrictsQuery, "G"));
  };
  useEffect(() => {
    get_districts_list();
  }, []);
  useEffect(() => {
    if (initialValues.name) {
      form.setFieldsValue(assign_initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);
  return (
    <Form
      initialValues={assign_initialValues}
      form={form}
      name={t("edit_society")}
      layout={"vertical"}
      onFinish={handleSubmit}
    >
      <VForm.TextBox
        label={t("form:society_name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: "Society Name is Required",
          },
        ]}
      />
      <VForm.TextBox
        label={t("form:society_code")}
        field={"username"}
        rules={[
          {
            required: true,
            message: "Society Code is Required",
          },
        ]}
        disabled={initialValues.username ? true : false}
      />
      {role === "Admin" && (
        <VForm.Select
          label={t("form:district")}
          field={"district_id"}
          options={get_districts}
          rules={[
            {
              required: true,
              message: "District is Required",
            },
          ]}
        />
      )}
      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button
          isLoading={initialValues?.id ? updateLoading : createLoading}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};

export default SocietyDetails;
