import { Button } from "antd";
import { Box } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BridgeLiveStatus = (props) => {
  const { handleModalClose } = props;
  const { t } = useTranslation();
  const history = useHistory();

  // const handleModalClose = () => {
  //   set_bridge_success_modal_open(false);
  //   history.goBack();
  // };
  return (
    <>
      <Box style={{ padding: "10px" }}>
        <text>{t("Pls_check_after_sts")}</text>
      </Box>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          padding: "10px",
        }}
      >
        <Button
          style={{
            height: "35px",
            width: "85px",
            backgroundColor: "#1677ff",
            color: "white",
          }}
          onClick={handleModalClose}
          color="#158d94"
        >
          {t("close")}
        </Button>
      </Box>
    </>
  );
};

export default BridgeLiveStatus;
