import React from "react";
import { Box, HStack, Text } from "native-base";
import vertace_logo from "@assets/icons/logo_white.png";

const SidebarFooter = (props) => {
  return (
    <Box
      w={"100%"}
      h={"100%"}
      backgroundColor={"#11111180"}
      justifyContent="center"
      alignItems="center"
    >
      <HStack justifyContent="center" alignItems="center" textAlign={"center"}>
       
        <a href="https://vertace.com/" target="blank" style={{ textDecoration: "none" }}>
        <Text color="#ffffff" fontSize={12} marginTop={0} >
          Powered By{" "}
        </Text>
        </a>

        <a href="https://vertace.com/" target="blank" >
          <img height="20" alt="vertace" src={vertace_logo} />
        </a>
      </HStack>
    </Box>
  );
};

export default SidebarFooter;
