import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Center,
  Text,
  Pressable,
  SimpleGrid,
  Button,
} from "native-base";
import VForm from "@views/components/ui/antd_form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  getDashboardDetails,
  getDistrictsQuery,
  useDynamicSelector,
  report_query,
} from "@services/redux";
import Loader from "react-js-loader";
import { retrieveItem } from "@helpers/storage";
import * as XLSX from "xlsx";
import { Table } from "antd";
import { VscCloudUpload } from "react-icons/vsc";
import { MdGroups, MdOutlineHistory, MdWifiTethering } from "react-icons/md";
import { SiVitest } from "react-icons/si";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BiMessageSquareX } from "react-icons/bi";
const DashboardDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  let role = retrieveItem("role");
  let user = retrieveItem("user");

  const [dashboard_items, setDashboardItems] = useState([]);
  const { items: get_districts } = useDynamicSelector("get_districts");
  const {
    total_society,
    not_started_process,
    live_society_count,
    testing_society_count,
    uploaded_without_error_count,
    uploaded_with_error_count,
    backlog_entry_count,
    loading: get_dashboard_loading,
  } = useDynamicSelector("get_super_adminDashboard");
  const { items: reports, loading: reports_loading } =
    useDynamicSelector("get_report");
  const get_dashboard_details = () => {
    let keys = [{ key: "get_super_adminDashboard", loading: true }];
    let variable = {
      district_list:
        role === "Admin" ||
        role === "rcs" ||
        role === "mdccb" ||
        role === "Zone Admin"
          ? dashboard_items
          : [user?.allowed_district],
    };
    dispatch(
      dynamicRequest(
        keys,
        getDashboardDetails,
        dashboard_items?.length === 0 && role !== "district_admin"
          ? null
          : variable,
        "G"
      )
    );
  };

  const get_reports = () => {
    let keys = [{ key: "get_report", loading: true }];
    let variable = {};
    dispatch(dynamicRequest(keys, report_query, variable));
  };
  const get_districts_list = () => {
    let keys = [{ key: "get_districts", loading: true }];
    dispatch(dynamicRequest(keys, getDistrictsQuery, "G"));
  };

  const dashboards = [
    {
      name: "Total Societies",
      value: null,
      icon: <MdGroups size={40} color="#3c84cc" />,
      color: "#316CA6",
      sub_color: "#2b6299",
      count: total_society,
    },
    {
      name: t("live"),
      value: "live_society_count",
      icon: <MdWifiTethering size={40} color="#31cd54" />,
      color: "#28a745",
      sub_color: "#249a3f",
      count: live_society_count,
    },
    {
      name: t("backlogs_entry"),
      value: "backlog_entry_count",
      icon: <MdOutlineHistory size={40} color="#a89aff" />,
      color: "#706bb0",
      sub_color: "#6762a2",
      count: backlog_entry_count,
    },
    {
      name: t("test_credential_given"),
      value: "testing_society_count",
      icon: <SiVitest size={40} color="#C1C2C2" />,
      color: "#6c757d",
      sub_color: "#60686f",
      count: testing_society_count,
    },
    {
      name: t("validated"),
      value: "uploaded_without_error_count",
      icon: <IoMdCheckmarkCircleOutline size={40} color="#831099" />,
      color: "#9C19E3",
      sub_color: "#8417BF",
      count: uploaded_without_error_count,
    },
    {
      name: t("uploaded_with_error"),
      value: "uploaded_with_error_count",
      icon: <VscCloudUpload size={40} color="#ffc020" />,
      color: "#F0A71F",
      sub_color: "#d6941b",
      count: uploaded_with_error_count,
    },
    {
      name: t("not_started"),
      value: "not_started_process",
      icon: <BiMessageSquareX size={40} color="#b80010" />,
      color: "#D10014",
      sub_color: "#BD0012",
      count: not_started_process,
    },
  ];

  let columns = [
    {
      title: t("table:s_no"),
      dataIndex: "s_no",
      key: "s_no",
      sortable: false,

      flex: 1,
      className: "center-align-header",
    },
    {
      title: t("table:district_name"),
      dataIndex: "district_name",
      key: "district_name",
      sortable: false,

      flex: 1,
      sorter: (a, b) => a.district_name.localeCompare(b.district_name),
      className: "center-align-header",
    },
    {
      title: t("table:total_society_count"),
      dataIndex: "total_society_count",
      key: "total_society_count",
      align: "right",
      flex: 1,
      sorter: (a, b) => a.total_society_count - b.total_society_count,
      className: "center-align-header",
    },
    {
      title: t("table:live"),
      dataIndex: "live_society_count",
      key: "live_society_count",
      align: "right",
      flex: 1,
      sorter: (a, b) => a.live_society_count - b.live_society_count,
      className: "center-align-header",
    },
    {
      title: t("table:backlog_entry"),
      dataIndex: "backlog_entry_count",
      key: "backlog_entry_count",
      align: "right",
      flex: 1,
      sorter: (a, b) => a.backlog_entry_count - b.backlog_entry_count,
      className: "center-align-header",
    },
    {
      title: t("table:testing_society_count"),
      dataIndex: "testing_society_count",
      key: "testing_society_count",
      align: "right",
      flex: 1,
      sorter: (a, b) => a.testing_society_count - b.testing_society_count,
      className: "center-align-header",
    },
    {
      title: t("table:uploaded_without_error_count"),
      dataIndex: "uploaded_without_error_count",
      key: "uploaded_without_error_count",
      align: "right",
      flex: 1,
      sorter: (a, b) =>
        a.uploaded_without_error_count - b.uploaded_without_error_count,
      className: "center-align-header",
    },
    {
      title: t("table:uploaded_with_error_count"),
      dataIndex: "uploaded_with_error_count",
      key: "uploaded_with_error_count",
      align: "right",
      flex: 1,
      sorter: (a, b) =>
        a.uploaded_with_error_count - b.uploaded_with_error_count,
      className: "center-align-header",
    },
    {
      title: t("table:not_started_process"),
      dataIndex: "not_started_process",
      key: "not_started_process",
      align: "right",
      flex: 1,
      sorter: (a, b) => a.not_started_process - b.not_started_process,
      className: "center-align-header",
    },
  ];

  useEffect(() => {
    get_districts_list();
    get_dashboard_details();
  }, []);
  useEffect(() => {
    get_dashboard_details();
  }, [dashboard_items]);
  useEffect(() => {
    if (
      role === "Admin" ||
      role === "rcs" ||
      role === "mdccb" ||
      role === "Zone Admin"
    ) {
      get_reports();
    }
  }, [role]);
  const handleChange = (values) => {
    setDashboardItems(values);
  };

  const handleRedirect = (value) => {
    const queryParams = new URLSearchParams();
    queryParams.set("param1", value);
    queryParams.set("param2", dashboard_items);

    history.push({
      pathname: "/society",
      search: `?${queryParams.toString()}`,
    });
  };

  const handleDownloadReport = () => {
    let inputFormat = [];
    reports?.forEach((x, i) => {
      inputFormat.push({
        "S.No": x.s_no,
        "District Name": x.district_name,
        "Total Societies": x?.total_society_count,
        "Live Societies": x.live_society_count,
        "Backlog Entry": x.backlog_entry_count,
        "Testing Societies": x.testing_society_count,
        Validated: x.uploaded_without_error_count,
        "Uploaded with Error": x.uploaded_with_error_count,
        "Not Started": x?.not_started_process,
        // Include other fields as necessary
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(inputFormat);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Society Report.xlsx");
  };

  return (
    <Box justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
      <Text fontSize="3xl" bold pt={4} pb={4}>
        Dashboard
      </Text>
      {(role === "Admin" ||
        role === "rcs" ||
        role === "mdccb" ||
        role === "Zone Admin") && (
        <Box width={"400px"}>
          <VForm.Select
            isSearchable={true}
            allowClear
            label={t("District")}
            field={"district_id"}
            options={get_districts}
            onChange={handleChange}
          />
        </Box>
      )}
      {get_dashboard_loading ? (
        <Center mt={"8vh"}>
          <Loader
            type="spinner-default"
            bgColor={"#349481"}
            color={"#349481"}
            size={50}
          />
        </Center>
      ) : (
        <SimpleGrid
          columns={{
            base: 1,
            xs: 1,
            md: 3,
            sm: 2,
            lg: 4,
            xl: 4,
            "2xl": 5,
          }}
          spacingY={"27px"}
          spacingX={21}
        >
          {dashboards.map((dashboard) => {
            return (
              <Box
                size="100px"
                backgroundColor={dashboard.color}
                style={{
                  cursor: "pointer",
                  width: "220px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Pressable
                  style={{
                    cursor:
                      dashboard.count > 0
                        ? // dashboard?.name === "Total Societies" ||
                          // dashboard?.name === "Not Started"
                          //   ? "default"
                          //   :
                          "pointer"
                        : "default",
                  }}
                  onPress={() => {
                    if (dashboard.count > 0) {
                      dashboard.value !== null
                        ? handleRedirect(dashboard.value)
                        : "";
                    }
                  }}
                >
                  <VStack
                    justifyContent={"space-between"}
                    alignItems={"space-around"}
                  >
                    <HStack
                      space={"10px"}
                      justifyContent={"space-around"}
                      // padding={"10px"}
                    >
                      <Box h={"80px"} justifyContent={"center"}>
                        {dashboard.icon}
                      </Box>
                      <Box h={"80px"} justifyContent={"center"}>
                        <Text
                          color={"white"}
                          fontSize={"26px"}
                          // fontStyle={"italic"}
                        >
                          {dashboard.count}
                        </Text>
                      </Box>
                    </HStack>
                    <Box
                      backgroundColor={dashboard.sub_color}
                      justifyContent={"center"}
                      width={"100%"}
                      height={"30px"}
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <Text
                        textAlign={"center"}
                        alignItems={"center"}
                        color={"white"}
                        fontSize={"14px"}
                      >
                        {dashboard.name}
                      </Text>
                    </Box>
                  </VStack>
                </Pressable>
              </Box>
            );
          })}
        </SimpleGrid>
      )}
      {(role === "Admin" ||
        role === "rcs" ||
        role === "mdccb" ||
        role === "Zone Admin") && (
        <Box mt="10" mb="10">
          <Box mb="2" alignItems={"end"}>
            <Button
              width={"100px"}
              onPress={() => {
                handleDownloadReport();
              }}
              isLoading={reports_loading}
              bg={"#158d94"}
              _text={{ color: "white" }}
            >
              Download
            </Button>
          </Box>
          <Table
            loading={reports_loading}
            columns={columns}
            dataSource={reports}
            pagination={false}
          />
        </Box>
      )}
    </Box>
  );
};
export default DashboardDetails;
