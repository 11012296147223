import { useDynamicSelector } from "@services/redux";
import { Button } from "antd";
import { Box, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const CredentialsDetails = ({ handleModalClose }) => {
  const { t } = useTranslation();

  const credentials_user_details = useDynamicSelector("getLiveCredential");

  return (
    <>
      <Box display="flex" justifyContent="center" mb="4">
        <Text fontSize="lg" fontWeight="bold">
          {"Credentials"}
        </Text>
      </Box>
      <Box display="flex" flexDirection="row" mb="2">
        <Box padding="2">
          <Text>{"username:"}</Text>
        </Box>
        <Box padding="2">
          <Text>{credentials_user_details?.username}</Text>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" mb="2">
        <Box padding="2">
          <Text>{"password:"}</Text>
        </Box>
        <Box padding="2">
          <Text>{credentials_user_details?.password}</Text>
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-end"
        flexDirection="row"
        padding="2"
      >
        <Button
          style={{
            height: "35px",
            width: "85px",
            backgroundColor: "#1677ff",
            color: "white",
          }}
          onClick={handleModalClose}
        >
          {t("close")}
        </Button>
      </Box>
    </>
  );
};

export default CredentialsDetails;
