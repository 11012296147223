import { gql } from "@apollo/client";

export const createSocietyMutation = gql`
  mutation createSociety($data: society_input) {
    create_society: createSociety(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateSocietyMutation = gql`
  mutation updateSociety($id: String!, $data: society_input) {
    update_society: updateSociety(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const deleteSocietyMutation = gql`
  mutation deleteSociety($id: String!) {
    delete_society: deleteSociety(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getAllSocietyQuery = gql`
  query getSocieties(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $status: String
    $district_id: String
    $from_date: String
    $to_date: String
  ) {
    get_all_society: getSocieties(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      status: $status
      district_id: $district_id
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        id
        username
        name
        latest_excel {
          uploaded_time
          status
          id
          is_second_level_validated
          total_user_excel_match_count
          total_match_count
        }
        district {
          id
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_society_query = gql`
  query getSociety($id: String) {
    getSociety(id: $id) {
      id
      username
      name
    }
  }
`;
export const getDistrictsQuery = gql`
  {
    get_districts: getDistricts {
      items {
        id
        name
      }
    }
  }
`;

export const check_excel_validation_mutation = gql`
  mutation demand_generation($excel_id: String) {
    demand_generation: demand_generation(excel_id: $excel_id) {
      society_name
      society_code
      contact_person_1
      contact_person_email
      share_capital_gl_amount
      share_capital_match
      member_share
      department_sub_department_array {
        department
        sub_department
      }
      loan_data {
        interest_payment_gl_name
        principal_payment_gl_name
        penal_interest_payment_gl_name
        principal_payment_gl_required_type
        principal_payment_gl_type
        principal_payment_gl_match_msg
        interest_payment_gl_required_type
        interest_payment_gl_type
        interest_payment_gl_match_msg
        penal_interest_gl_required_type
        penal_interest_payment_gl_type
        penal_interest_payment_gl_match_msg
        surety_loan_gl_amount
        surety_loan_gl_amount_match
        surety_loan_outstanding_sum
        code
      }
      deposit_data {
        deposit_gl_name
        principal_payment_gl_name
        interest_payment_gl_name
        principal_payment_gl_required_type
        principal_payment_gl_type
        principal_payment_gl_type_match
        interest_payment_gl_required_type
        interest_payment_gl_type
        interest_payment_gl_type_match
        deposit_gl_amount
        deposit_outstanding_sum
        code
        name
        amount_match
        amount_match_msg
      }

      staff_loan_data {
        principal_payment_gl_required_type
        principal_payment_gl_type
        principal_payment_gl_match_msg
        principal_payment_gl_match
        principal_payment_gl_name
        interest_payment_gl_required_type
        interest_payment_gl_type
        interest_payment_gl_match
        interest_payment_gl_match_msg
        interest_payment_gl_name
        penal_interest_gl_required_type
        penal_interest_payment_gl_type
        penal_interest_payment_gl_match
        penal_interest_payment_gl_match_msg
        penal_interest_payment_gl_name
        staff_loan_gl_amount
        staff_loan_gl_amount_match
        staff_loan_outstanding_sum
        code
      }
      fd_deposit {
        principal_payment_gl_required_type
        principal_payment_gl_type
        principal_payment_gl_type_match
        principal_payment_gl_name
        interest_payment_gl_required_type
        interest_payment_gl_type
        interest_payment_gl_type_match
        interest_payment_gl_name
        deposit_gl_amount
        deposit_outstanding_sum
        deposit_gl_name
        code
        name
        amount_match
        amount_match_msg
      }
      rd_deposit {
        principal_payment_gl_required_type
        principal_payment_gl_type
        principal_payment_gl_type_match
        principal_payment_gl_name
        interest_payment_gl_required_type
        interest_payment_gl_type
        interest_payment_gl_type_match
        interest_payment_gl_name
        deposit_gl_amount
        deposit_outstanding_sum
        deposit_gl_name
        code
        name
        amount_match
        amount_match_msg
      }
      jewel_loan {
        principal_payment_gl_required_type
        principal_payment_gl_type
        principal_payment_gl_match_msg
        principal_payment_gl_match
        principal_payment_gl_name
        interest_payment_gl_required_type
        interest_payment_gl_type
        interest_payment_gl_match
        interest_payment_gl_match_msg
        interest_payment_gl_name
        penal_interest_gl_required_type
        penal_interest_payment_gl_type
        penal_interest_payment_gl_match
        penal_interest_payment_gl_match_msg
        penal_interest_payment_gl_name
        loan_gl_amount
        loan_gl_amount_match
        loan_outstanding_sum
        code
      }
      rd_loan {
        principal_payment_gl_required_type
        principal_payment_gl_type
        principal_payment_gl_match_msg
        principal_payment_gl_match
        principal_payment_gl_name
        interest_payment_gl_required_type
        interest_payment_gl_type
        interest_payment_gl_match
        interest_payment_gl_match_msg
        interest_payment_gl_name
        penal_interest_gl_required_type
        penal_interest_payment_gl_type
        penal_interest_payment_gl_match
        penal_interest_payment_gl_match_msg
        penal_interest_payment_gl_name
        loan_gl_amount
        loan_gl_amount_match
        loan_outstanding_sum
        code
      }
      fd_loan {
        principal_payment_gl_required_type
        principal_payment_gl_type
        principal_payment_gl_match_msg
        principal_payment_gl_match
        principal_payment_gl_name
        interest_payment_gl_required_type
        interest_payment_gl_type
        interest_payment_gl_match
        interest_payment_gl_match_msg
        interest_payment_gl_name
        penal_interest_gl_required_type
        penal_interest_payment_gl_type
        penal_interest_payment_gl_match
        penal_interest_payment_gl_match_msg
        penal_interest_payment_gl_name
        loan_gl_amount
        loan_gl_amount_match
        loan_outstanding_sum
        code
      }
      non_member_due_to
      due_to_gl
      due_to_gl_match_msg
      non_member_due_by
      due_by_gl
      due_by_gl_match_msg
      member_due_to
      due_to_total
      member_due_by
      due_by_total
      due_by_gl_names
      due_to_gl_names
      investment_group_array {
        gl_account
        total_amount
        amount_in_gl
        amount_match
      }
      borrowing_group_array {
        gl_account
        total_amount
        amount_in_gl
        amount_match
      }

      error {
        status_code
        message
      }
    }
  }
`;
