import React, { useEffect } from "react";
import {
  Box,
  SimpleGrid,
  VStack,
  HStack,
  Text,
  Pressable,
  Image,
} from "native-base";
import CheckImage from "@assets/icons/check-mark.png";
import DroppedImage from "@assets/icons/cancel.png";
import { GoCreditCard } from "react-icons/go";
import { MdGroups, MdOutlineHistory, MdWifiTethering } from "react-icons/md";
import { SiVitest } from "react-icons/si";
import { VscCloudUpload } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import { GiEntryDoor } from "react-icons/gi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  get_zone_admin_dashboard_query,
  useDynamicSelector,
} from "@services/redux";
import ActionItemDashboardCardSkeletonLoading from "./action_item_dashboard_card_skeleton";
import { BiMessageSquareX } from "react-icons/bi";

const ActionItemDashboardCard = ({ district_ids }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    total_society,
    not_started_process,
    live_society_count,
    testing_society_count,
    uploaded_without_error_count,
    uploaded_with_error_count,
    backlog_entry_count,
    loading,
  } = useDynamicSelector("get_zone_admin_dashboard");

  useEffect(() => {
    get_zone_admin_dashboard_counts();
  }, [district_ids]);

  const get_zone_admin_dashboard_counts = () => {
    let keys = [{ key: "get_zone_admin_dashboard", loading: true }];
    let variable = {
      district_list: district_ids?.[district_ids] || district_ids,
    };
    dispatch(dynamicRequest(keys, get_zone_admin_dashboard_query, variable));
  };
  const dashboards = [
    {
      name: "Total Societies",
      value: null,
      icon: <MdGroups size={40} color="#3c84cc" />,
      color: "#316CA6",
      sub_color: "#2b6299",
      count: total_society,
    },
    {
      name: t("live"),
      value: "live_society_count",
      icon: <MdWifiTethering size={40} color="#31cd54" />,
      color: "#28a745",
      sub_color: "#249a3f",
      count: live_society_count,
    },
    {
      name: t("backlogs_entry"),
      value: "backlog_entry_count",
      icon: <MdOutlineHistory size={40} color="#a89aff" />,
      color: "#706bb0",
      sub_color: "#6762a2",
      count: backlog_entry_count,
    },
    {
      name: t("test_credential_given"),
      value: "testing_society_count",
      icon: <SiVitest size={40} color="#C1C2C2" />,
      color: "#6c757d",
      sub_color: "#60686f",
      count: testing_society_count,
    },
    {
      name: t("validated"),
      value: "uploaded_without_error_count",
      icon: <IoMdCheckmarkCircleOutline size={40} color="#831099" />,
      color: "#9C19E3",
      sub_color: "#8417BF",
      count: uploaded_without_error_count,
    },
    {
      name: t("uploaded_with_error"),
      value: "uploaded_with_error_count",
      icon: <VscCloudUpload size={40} color="#ffc020" />,
      color: "#F0A71F",
      sub_color: "#d6941b",
      count: uploaded_with_error_count,
    },
    {
      name: t("not_started"),
      value: "not_started_process",
      icon: <BiMessageSquareX size={40} color="#b80010" />,
      color: "#D10014",
      sub_color: "#BD0012",
      count: not_started_process,
    },
  ];

  return (
    <Box width="100%" px="15px">
      {loading ? (
        <SimpleGrid
          columns={{
            base: 1,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            "2xl": 5,
          }}
          spacingY="27px"
          spacingX="21px"
        >
          {Array.from({ length: dashboards.length }).map((_, index) => (
            <ActionItemDashboardCardSkeletonLoading key={index} />
          ))}
        </SimpleGrid>
      ) : (
        <SimpleGrid
          columns={{
            base: 1,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            "2xl": 5,
          }}
          spacingY="27px"
          spacingX="21px"
        >
          {dashboards.map((dashboard) => (
            <Box
              key={dashboard.name}
              backgroundColor={dashboard.color}
              cursor="pointer"
              width="240px"
              height="150px"
              borderRadius="10px"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            >
              <VStack
                justifyContent="space-between"
                alignItems="center"
                height="100%"
              >
                <HStack
                  spacing="10px"
                  alignItems="center"
                  justifyContent="space-between"
                  height="80px"
                  width="90%"
                >
                  <Box>{dashboard.icon}</Box>
                  <Text color="white" fontSize="26px">
                    {dashboard.count}
                  </Text>
                </HStack>
                <Box
                  backgroundColor={dashboard.sub_color}
                  justifyContent="center"
                  width="100%"
                  height="30px"
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  textAlign="center"
                  display="flex"
                  alignItems="center"
                  borderBottomRadius="10px"
                >
                  <Text color="white" fontSize="14px">
                    {dashboard.name}
                  </Text>
                </Box>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default ActionItemDashboardCard;
