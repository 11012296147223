import logo from "@assets/icons/logo.png";
import React, { Fragment } from "react";
import {
  HStack,
  VStack,
  Text,
  Hidden,
  Box,
  ScrollView,
  Center,
} from "native-base";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import {
  dynamicClear,
  dynamicRequest,
  force_password_change_query,
  useDynamicSelector,
} from "@services/redux";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Button, Form, Input } from "antd";
import { retrieveItem, storeItem } from "@helpers/storage";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const ForgotPasswordForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const role = retrieveItem("role");

  const { status, loading, error } = useDynamicSelector(
    "force_password_change"
  );

  useEffect(() => {
    if (error?.message) {
      toast.error(error.message);
    }
    if (status === "success") {
      toast.success(t("password_updated"));
      storeItem("force_password_change", false);
      history.push(
        role !== "Zone Admin" ? ROUTES.FILE_UPLOAD_DETAILS : ROUTES.DASHBOARD
      );
    }
    dispatch(dynamicClear("force_password_change"));
    return () => {};
  }, [status]);

  const updateNewPassword = (values) => {
    if (values.password !== values.confirm_password) {
      toast.error("New password & Confirm Password must be the same");
      return;
    }
    let force_password_change_key = [
      { key: "force_password_change", loading: true },
    ];
    let force_password_change_variables = {
      password: values.password,
    };
    dispatch(
      dynamicRequest(
        force_password_change_key,
        force_password_change_query,
        force_password_change_variables
      )
    );
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "white",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
      >
        <VStack space="7">
          <Hidden till="md">
            <HStack justifyContent={"center"} alignItems="center">
              <Text
                color={"#396591"}
                bold
                fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
                justifyContent="center"
                display="flex"
                fontWeight="normal"
              >
                {"Change Password"}
              </Text>
            </HStack>
          </Hidden>
          <VStack>
            <VStack space="3">
              <Box mx={5}>
                <Form
                  form={form}
                  name={"force-password-change-form"}
                  layout={"vertical"}
                  onFinish={updateNewPassword}
                >
                  <Form.Item
                    label="New Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your new password!",
                      },
                    ]}
                  >
                    <Input.Password
                      w={{
                        base: "100%",
                      }}
                      iconRender={(visible) =>
                        visible ? <Fragment /> : <Fragment />
                      }
                      placeholder="Password"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Confirm New Password"
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your new password!",
                      },
                    ]}
                  >
                    <Input.Password
                      w={{
                        base: "100%",
                      }}
                      iconRender={(visible) =>
                        visible ? <Fragment /> : <Fragment />
                      }
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Center>
                      <Button
                        htmlType="submit"
                        loading={loading}
                        type="primary"
                        style={{ width: "150px" }}
                      >
                        Change Password
                      </Button>
                    </Center>
                  </Form.Item>
                </Form>
              </Box>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </ScrollView>
  );
};

export default ForgotPasswordForm;
