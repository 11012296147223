import { gql } from "@apollo/client";

export const get_additional_excel_list_query = gql`
  query getAdditionalExcels(
    $search_string: String
    $status: String
    $user_id: String
    $page_number: Int
    $page_limit: Int
  ) {
    getAdditionalExcels(
      search_string: $search_string
      status: $status
      user_id: $user_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        excel_url
        error_type
        is_valid
        uploaded_time
        completed_time
        errors
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_additional_excel_query = gql`
  query getAdditionalExcel($id: String) {
    getAdditionalExcel(id: $id) {
      id
      excel_url
      error_type
      is_valid
      uploaded_time
      completed_time
      errors
      error {
        status_code
        message
      }
    }
  }
`;

export const additional_excel_import_mutation = gql`
  mutation additional_excel_import($file: String) {
    additional_excel_import(file: $file) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
