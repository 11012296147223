import { gql } from "@apollo/client";

export const getDashboardDetails = gql`
  query getSuperAdminDashboard($district_list: [String]) {
    get_super_adminDashboard: getSuperAdminDashboard(
      district_list: $district_list
    ) {
      total_society
      not_started_process
      live_society_count
      testing_society_count
      uploaded_without_error_count
      uploaded_with_error_count
      backlog_entry_count
    }
  }
`;
