import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Center, HStack, Text } from "native-base";

const OfficerDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSubmit = (values) => {
    if (initialValues?.id) {
    } else {
    }
  };
  const district_list = [
    {
      name: "Ariyalur",
      value: "Ariyalur",
    },
    {
      name: "Chennai",
      value: "Chennai",
    },
    {
      name: "Erode",
      value: "Erode",
    },
  ];
  return (
    <Box flex={1}>
      <Form
        form={form}
        name={"society_details"}
        layout={"vertical"}
        onFinish={handleSubmit}
      >
        <VForm.TextBox label={t("form:name")} field="name" />
        <VForm.Select
          label={t("form:district")}
          field="district_id"
          isMulti={true}
          options={district_list}
          labelField="name"
          valueField="value"
        />
        <VForm.TextBox label={t("form:designation")} field="designation" />

        <HStack space="10" justifyContent={"flex-end"}>
          <VForm.Button
          //   isLoading={initialValues?.id ? updateLoading : createLoading}
          >
            {t("submit")}
          </VForm.Button>
          <Button onClick={props.close}>{t("close")}</Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default OfficerDetails;
