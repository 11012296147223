import React from "react";
import { Skeleton } from "antd";
import { Box, HStack, VStack } from "native-base";

const ActionItemDashboardCardSkeletonLoading = () => {
  return (
    <Box
      backgroundColor="#E0E0E0"
      width={{
        base: "100%",
        sm: "240px",
      }}
      height="150px"
      borderRadius="10px"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
    >
      <Skeleton.Button
        style={{
          minWidth: "18.7vw",
          maxWidth: "28vw",
          height: "150px",
        }}
        active
      />
      {/* </VStack> */}
    </Box>
  );
};

export default ActionItemDashboardCardSkeletonLoading;
