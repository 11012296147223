import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_test_credentials_society_details_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_action_items";
import { Pagination, Collapse, Table, Button } from "antd";
import moment from "moment";
import { HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const { Panel } = Collapse;

const ActionItemsTestCredentialsTable = ({ showModal, district_ids }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const {
    items: test_credentials_details_list,
    pagination,
    loading,
  } = useDynamicSelector("get_test_credentials_society_details");

  useEffect(() => {
    get_test_credentials_details_list();
  }, [page_number, page_limit, district_ids]);

  const get_test_credentials_details_list = () => {
    let keys = [{ key: "get_test_credentials_society_details", loading: true }];
    let variable = {
      status: "testing_society_count",
      page_number: page_number,
      page_limit: page_limit,
      district_id: district_ids,
    };
    dispatch(
      dynamicRequest(keys, get_test_credentials_society_details_query, variable)
    );
  };

  const test_columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => (
        <span>{(page_number - 1) * page_limit + index + 1}</span>
      ),
    },
    {
      title: "Society Code",
      dataIndex: "username",
      key: "username",
      render: (text, record) => {
        return record?.username ? record.username.toUpperCase() : "";
      },
    },
    { title: "Society Name", dataIndex: "name", key: "name" },
    {
      title: "Secretary",
      dataIndex: "secretary",
      key: "secretary",
      render: (text, record) =>
        record.secretary_name
          ? `${record.secretary_name} (${record.secretary_mobile_number})`
          : "N/A",
    },
    {
      title: "CSR",
      dataIndex: "district_officer",
      key: "csrNo",
      render: (text, record) =>
        record.district_officer
          ?.map((officer) => `${officer.name} (${officer.mobile_number})`)
          .join(", "),
    },

    {
      title: "Test Credentials Given Date",
      dataIndex: "test_credentials_given_date",
      key: "test_credentials_given_date",
      render: (text, record) => (
        <span>
          {moment(parseInt(record?.test_credentials_given_date, 10)).format(
            "YYYY-MM-DD"
          )}
        </span>
      ),
    },
    {
      title: "Note",
      key: "note",
      render: (text, record) => (
        <Button
          onClick={() => showModal(record?.id)}
          style={{
            backgroundColor: "#1a98be",
            color: "white",
            cursor: "pointer",
          }}
        >
          Note
        </Button>
      ),
    },
  ];

  const fetchData = (val, value) => {
    set_page_number(val);
    set_page_limit(10);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      {/* <Text
        style={{ fontWeight: "bold", fontSize: "20px", marginLeft: "10px" }}
      >
        {t("test_credential_given")}
      </Text> */}

      <Collapse accordion>
        <Panel
          header={
            <div>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginLeft: "10px",
                  color: "white",
                }}
              >
                {t("test_credential_given")}
              </Text>
            </div>
          }
          style={{ backgroundColor: "#373738" }}
        >
          <Table
            columns={test_columns}
            dataSource={test_credentials_details_list}
            pagination={false}
            loading={loading}
          />

          <HStack justifyContent={"flex-end"} marginTop={"10px"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              current={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ActionItemsTestCredentialsTable;
