import FileUploadDetailsList from "@views/components/file_upload_details/file_upload_list";
import { Box } from "native-base";
import React from "react";

const FileUploadListPage = () => {
  return (
    <Box>
      <FileUploadDetailsList />
    </Box>
  );
};

export default FileUploadListPage;
