import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import {
  create_note_mutation,
  get_notes_query,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_action_items";
import ActionItemsLiveSocietyTable from "./action_items_live_society_table";
import ActionItemsTestCredentialsTable from "./action_items_test_credentials_table";
import ActionItemsBacklogTable from "./action_items_backlog_table";
import ActionItemsManualCheckPendingTable from "./action_items_manual_check_pending_table";
import ActionItemsValidatedTable from "./action_items_validated_table";
import ActionItemsCustomModal from "./action_items_custom_modal";
import moment from "moment";
import { Form } from "antd";

const ActionItemsTable = ({ district_ids }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [society_id, set_society_id] = useState("");
  const { items: notes_list, pagination } = useDynamicSelector("getNotes");
  const [note, set_note] = useState("");
  const [form] = Form.useForm();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const { status: create_note_status, error: create_note_error } =
    useDynamicSelector("createNote");

  const showModal = (value) => {
    set_society_id(value);
    setIsModalVisible(true);
    get_note_list(value);
  };

  useEffect(() => {
    if (create_note_status === "Success") {
      toast.success("Note Created Successfully");
      get_note_list(society_id);
      set_note("");
      form.resetFields();
    } else if (create_note_error) {
      toast.error("Note Creation Failed");
    }
    dispatch(dynamicClear("createNote"));
  }, [create_note_status, create_note_error, dispatch]);

  const create_note = (value) => {
    const keys = [{ key: "createNote", loading: true }];
    const variable = { data: { society_id, description: value } };
    dispatch(dynamicRequest(keys, create_note_mutation, variable));
  };

  useEffect(() => {
    if (society_id) {
      get_note_list();
    }
  }, [page_number, page_limit]);

  const get_note_list = (value) => {
    const keys = [{ key: "getNotes", loading: true }];
    const variable = { society_id: value, page_number, page_limit };
    dispatch(dynamicRequest(keys, get_notes_query, variable));
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    create_note(values.note);
    set_note(values.note);
  };

  const modalColumns = [
    {
      title: "Date",
      dataIndex: "created_datetime",
      key: "date",
      render: (text) => moment(parseInt(text)).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Name",
      dataIndex: "created_by",
      key: "name",
      render: (created_by) => created_by?.name,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const fetchData = (page, limit) => {
    set_page_number(page);
    set_page_limit(limit);
  };

  return (
    <div style={{ paddingTop: "35px", paddingBottom: "10px", gap: "10px" }}>
      <ActionItemsLiveSocietyTable
        showModal={showModal}
        district_ids={district_ids}
      />
      <ActionItemsBacklogTable
        showModal={showModal}
        district_ids={district_ids}
      />
      <ActionItemsTestCredentialsTable
        showModal={showModal}
        district_ids={district_ids}
      />
      <ActionItemsValidatedTable
        showModal={showModal}
        district_ids={district_ids}
      />
      <ActionItemsManualCheckPendingTable
        showModal={showModal}
        district_ids={district_ids}
      />
      <ActionItemsCustomModal
        isVisible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        onFinish={onFinish}
        columns={modalColumns}
        data={notes_list}
        rowKey="id"
        form={form}
        fetchData={fetchData}
        pagination={pagination}
        page_number={page_number}
      />
    </div>
  );
};

export default ActionItemsTable;
