import React from "react";
import { Box, Image, Pressable } from "native-base";
import { IoMdClose } from "react-icons/io";
import image from "@assets/images/logo.png";

const SidebarHeader = (props) => {
  return (
    <Box minH="80px">
      <Box
        w={"100%"}
        h={"100%"}
        justifyContent="center"
        alignItems="center"
        background="#1799c7"
      >
        <img height="70px" alt="logo" src={image} />
      </Box>
    </Box>
  );
};

export default SidebarHeader;
