import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Link,
  Hidden,
  Box,
  ScrollView,
  Center,
} from "native-base";

import { Input, Button, Form } from "antd";
import { navigate } from "@helpers/navigator";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  user_login_query,
} from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { storeItem } from "@helpers/storage";
import { useTranslation } from "react-i18next";

const LoginForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const login_data = useDynamicSelector("user_login");
  useEffect(() => {
    if (login_data.status === "success") {
      if (login_data.error) {
        toast.error(login_data.error.message);
        dispatch(dynamicClear("user_login"));
      } else {
        storeItem("token", login_data.token);
        storeItem("user", login_data);
        storeItem("name", login_data.name);
        storeItem("role", login_data.role);
        storeItem("user_id", login_data.user_id);
        storeItem("force_password_change", login_data.force_password_change);
        if (login_data.token && login_data.force_password_change) {
          navigate(ROUTES.FORCE_PASSWORD_CHANGE);
        } else if (login_data.token && login_data.role === "Admin") {
          navigate(ROUTES.DASHBOARD);
        } else if (login_data.token && login_data.role === "Society") {
          navigate(ROUTES.FILE_UPLOAD_DETAILS);
        } else if (login_data.token && login_data.role === "district_admin") {
          navigate(ROUTES.DASHBOARD);
        } else if (login_data.token && login_data.role === "mdccb") {
          navigate(ROUTES.DASHBOARD);
        } else if (login_data.token && login_data.role === "rcs") {
          navigate(ROUTES.DASHBOARD);
        } else if (login_data.token && login_data.role === "Zone Admin") {
          navigate(ROUTES.DASHBOARD);
        }
      }
      dispatch(dynamicClear("user_login"));
    } else {
      if (login_data?.error?.message?.includes("prisma")) {
        toast.error("Something went wrong");
      } else if (login_data?.error?.message)
        toast.error(login_data?.error.message);
      dispatch(dynamicClear("user_login"));
    }
  }, [login_data.status]);

  const login = (values) => {
    let user_login_key = [{ key: "user_login", loading: true }];
    let user_login_variables = {
      username: values.username,
      password: values.password,
    };

    dispatch(
      dynamicRequest(user_login_key, user_login_query, user_login_variables)
    );
  };

  const [form] = Form.useForm();

  const goBack = () => {
    window.location.replace("https://www.kootturavu.com/home");
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "white",
          opacity: "0.9",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
      >
        <VStack space="7">
          <Hidden till="md">
            <HStack justifyContent={"center"} alignItems="center">
              <Text
                color={"#396591"}
                bold
                fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
                justifyContent="center"
                display="flex"
                fontWeight="normal"
              >
                {"Sign In"}
              </Text>
            </HStack>
          </Hidden>
          <VStack>
            <VStack space="3">
              <Box mx={5}>
                <Form
                  form={form}
                  name={"login-form"}
                  layout={"vertical"}
                  onFinish={login}
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your username!",
                      },
                    ]}
                  >
                    <Input placeholder="Username" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      w={{
                        base: "100%",
                      }}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Center>
                      <Button
                        htmlType="submit"
                        loading={login_data.loading}
                        type="primary"
                        style={{ width: "150px" }}
                      >
                        SIGN IN
                      </Button>
                      <div className="d-flex justify-content-center">
                        <h4
                          className="change-password-back-btn"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            width: "50px",
                            textAlign: "center",
                          }}
                          onClick={goBack}
                        >
                          {t("back")}
                        </h4>
                      </div>
                    </Center>
                  </Form.Item>
                </Form>
              </Box>
              {/* <Link
                ml="auto"
                _text={{
                  fontSize: "xs",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                _light={{
                  _text: {
                    color: "#056cb4",
                  },
                }}
                _dark={{
                  _text: {
                    color: "#056cb4",
                  },
                }}
                onPress={() => {
                  showForgotPasswordForm(true);
                  showLoginForm(false);
                  dispatch(dynamicClear("request_forgot_password_otp"));
                }}
              >
                Forgot password?
              </Link> */}
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </ScrollView>
  );
};

export default LoginForm;
