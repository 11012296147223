import ActionItemsDistrictOption from "@views/components/ui/action_items/action_items_district_option";
import React, { useState } from "react";
import ActionItemsTitle from "../../components/ui/action_items/action_items_title";
import ActionItemDashboardCard from "@views/components/ui/action_items/action_item_dashboard_card";
import ActionItemsTable from "@views/components/ui/action_items/action_items_table";

const ActionItems = () => {
  const [district_ids, set_district_ids] = useState();
  return (
    <div>
      <ActionItemsTitle />
      <ActionItemsDistrictOption set_district_ids={set_district_ids} />
      <ActionItemDashboardCard district_ids={district_ids} />
      <ActionItemsTable district_ids={district_ids} />
    </div>
  );
};

export default ActionItems;
