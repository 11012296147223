import { navigate } from "@helpers/navigator";
import { retrieveItem } from "@helpers/storage";
import { ROUTES } from "@views/routes/my_routes";
import React, { useEffect } from "react";

const Redirector = () => {
  useEffect(() => {
    let token = retrieveItem("token");
    if (token) {
      navigate(ROUTES.FILE_UPLOAD_DETAILS);
    } else {
      navigate(ROUTES.LOGIN);
    }
  }, []);
  return <></>;
};
export default Redirector;
