import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { dynamicRequest } from "@services/redux";
import { deleteSocietyMutation } from "@services/redux";
import { useTranslation } from "react-i18next";
import { Box, Divider, HStack, Text } from "native-base";

const SocietyDelete = (props) => {
  const { t } = useTranslation();
  const { initialValues, onClose } = props;
  const dispatch = useDispatch();

  const handleDelete = () => {
    let keys = [{ key: "delete_market_committee", loading: true }];
    dispatch(
      dynamicRequest(
        keys,
        deleteSocietyMutation,
        {
          id: initialValues?.id,
        },
        "M"
      )
    );
  };

  return (
    <Box minH={"100px"}>
      <Text>
        {` Are you sure you want to delete this "${initialValues?.name}" society?`}
      </Text>
      <Box>
        <Divider mt="8" />
        <HStack justifyContent={"flex-end"} mt="4" space="5">
          <Button
            onClick={() => {
              handleDelete();
            }}
            type="primary"
            danger
          >
            {t("delete")}
          </Button>
          <Button type="default" onClick={props.close}>
            {t("cancel")}
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};
export default SocietyDelete;
