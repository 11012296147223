import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack, Pressable, Spacer } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import VForm from "@views/components/ui/antd_form";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { getAllSocietyQuery, getDistrictsQuery } from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Button, DatePicker, Form, Pagination, Select, Table } from "antd";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import moment from "moment";
import SearchBox from "../ui/search_box/search_box";
import { MdBugReport } from "react-icons/md";
import { startCase } from "lodash";
import UserDetails from "./user_details";
import AntdModal from "../ui/antd_modal/antd_modal";

const UserList = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [dialog_visible, setDialogVisible] = useState(false);
  const [action_item, setActionItem] = useState({});
  const [action_type, setActionType] = useState(null);
  const [model_open, setModalOpen] = useState(false);
  const [report_modal_open, setReportModalOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [diststatus, setDistStatus] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const { t } = useTranslation();

  const { error: createError, status: createStatus } =
    useDynamicSelector("create_society");
  const { error: updateError, status: updateStatus } =
    useDynamicSelector("update_society");

  const {
    items: societies,
    pagination,
    loading,
  } = useDynamicSelector("get_all_society");

  const { items: Districts } = useDynamicSelector("get_districts");
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_society");

  const handleDeleteModalOpen = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const handleDeleteModalClose = (item) => {
    setDialogVisible(false);
  };
  const dateFormat = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const milliseconds = date.getMilliseconds();

    const formattedDate =
      `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`.replace(
        "/",
        "-"
      );

    return formattedDate;
  };

  const getSocieties = (should_load) => {
    let keys = [{ key: "get_all_society", loading: should_load }];
    dispatch(
      dynamicRequest(keys, getAllSocietyQuery, {
        page_number: current,
        page_limit: current_limit,
        search_string: search,
        status: status,
        district_id: diststatus,
        from_date: fromDate ? dateFormat(fromDate) : null,
        to_date: toDate ? dateFormat(toDate) : null,
      })
    );
  };
  const getDistrict = (should_load) => {
    let keys = [{ key: "get_districts", loading: should_load }];
    dispatch(dynamicRequest(keys, getDistrictsQuery));
  };

  useEffect(() => {
    getSocieties(true);
  }, [status, diststatus, toDate, fromDate, status]);

  useEffect(() => {
    getDistrict();
  }, []);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
    form.resetFields();
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
    // form.resetFields();
    form.setFieldsValue(item);
  };

  const handleReport = (item) => {
    setActionItem(item);
    setReportModalOpen(true);
  };

  const handleReportClose = () => {
    setReportModalOpen(false);
  };

  useEffect(() => {
    if (action_type === "add" && createStatus === "Success") {
      handleModalClose();
      showToast({
        type: "success",
        message: `Society added successfully`,
      });
      getSocieties();
      dispatch(dynamicClear("create_society"));
    } else if (action_type === "add" && createError) {
      showToast({
        type: "error",
        message: `Something went wrong!`,
      });
      getSocieties();
      dispatch(dynamicClear("create_society"));
    } else if (action_type === "edit" && updateStatus === "Success") {
      handleModalClose();
      showToast({
        type: "success",
        message: `Society updated successfully`,
      });
      getSocieties();
      dispatch(dynamicClear("update_society"));
    } else if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `Society deleted successfully`,
      });
      dispatch(dynamicClear("delete_society"));
      getSocieties();
      handleDeleteModalClose();
    } else if (deleteError) {
      showToast({
        type: "error",
        message: `Something went wrong!`,
      });
      dispatch(dynamicClear("delete_society"));
      getSocieties();
      handleDeleteModalClose();
    }
  }, [
    createStatus,
    updateStatus,
    deleteStatus,
    updateError,
    createError,
    deleteError,
  ]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_society", loading: true }];
    dispatch(
      dynamicRequest(keys, getAllSocietyQuery, {
        page_number: page_number,
        page_limit: page_limit,
        status: status,
        search_string: search,
        from_date: fromDate ? dateFormat(fromDate) : null,
        to_date: toDate ? dateFormat(toDate) : null,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleSearch = (search_txt) => {
    let keys = [{ key: "", loading: true }];
    let variables = {
      search_string: search_txt,
      page_limit: current_limit,
      page_number: current,
      status: status,
      from_date: fromDate ? dateFormat(fromDate) : null,
      to_date: toDate ? dateFormat(toDate) : null,
    };
    dispatch(dynamicRequest(keys, variables));
    setSearch(search_txt);
  };

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:district"),
      // dataIndex: "district.[name]",
      // key: "district.[name]",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        // return <Text>{record.district ? record?.district?.name : "-"}</Text>;
      },
    },
  ];

  return (
    <>
      <Box top="10px" zIndex="2">
        <Text fontSize="md" fontWeight="bold">
          {t("User")}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            <Box key={`header-action`} alignSelf={"center"} marginBottom={7}>
              <Button
                onClick={handleAdd}
                color="#158d94"
                style={{
                  height: "50px",
                }}
              >
                {t("Add User")}
              </Button>
            </Box>
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              columns={columns}
              dataSource={societies}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={fetchData}
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={model_open}
        onClose={handleModalClose}
        width={450}
        footer={null}
        header={action_type === "add" ? t("add_user") : t("edit_user")}
        component={
          <UserDetails
            label={action_type === "add" ? t("add") : t("update")}
            close={handleModalClose}
            // initialValues={action_item}
          />
        }
      />
      {/* <AntdModal
        isOpen={dialog_visible}
        onClose={handleDeleteModalClose}
        width={450}
        footer={null}
        header={t("delete_society")}
        component={
        //   <SocietyDelete
        //     close={handleModalClose}
        //     initialValues={action_item}
        //     form={form}
        //   />
        }
      />
      <AntdModal
        isOpen={report_modal_open}
        onClose={handleReportClose}
        width={1000}
        footer={null}
        header={t("Reports")}
        component={
        //   <Reports close={handleReportClose} initialValues={action_item} />
        }
      /> */}
    </>
  );
};
export default UserList;
