import {
  dynamicRequest,
  getDistrictsQuery,
  useDynamicSelector,
} from "@services/redux";
import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const ActionItemsDistrictOption = ({ set_district_ids }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: get_districts } = useDynamicSelector("get_districts");

  useEffect(() => {
    get_districts_list();
  }, []);

  const get_districts_list = () => {
    let keys = [{ key: "get_districts", loading: true }];
    dispatch(dynamicRequest(keys, getDistrictsQuery, "G"));
  };
  const handleChange = (values) => {
    set_district_ids(values);
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <div style={{ width: "400px", paddingRight: "15px" }}>
        <VForm.Select
          isSearchable={true}
          allowClear
          label={t("District")}
          field={"district_id"}
          options={get_districts}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default ActionItemsDistrictOption;
