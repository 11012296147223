import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_manual_check_pending_society_details_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_action_items";
import { Button, Collapse, Pagination, Table } from "antd";
import { HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const ActionItemsManualCheckPendingTable = ({ showModal, district_ids }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const {
    items: manual_check_pending_society_details_list,
    pagination,
    loading,
  } = useDynamicSelector("get_manual_check_pending_society_details");

  useEffect(() => {
    get_manual_check_pending_society_details_list();
  }, [page_number, page_limit, district_ids]);

  const get_manual_check_pending_society_details_list = () => {
    let keys = [
      { key: "get_manual_check_pending_society_details", loading: true },
    ];
    let variable = {
      status: "uploaded_with_od_error",
      page_number: page_number,
      page_limit: page_limit,
      district_id: district_ids,
    };
    dispatch(
      dynamicRequest(
        keys,
        get_manual_check_pending_society_details_query,
        variable
      )
    );
  };
  const fetchData = (val, value) => {
    set_page_number(val);
    set_page_limit(value);
  };

  const manual_check_columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => (
        <span>{(page_number - 1) * page_limit + index + 1}</span>
      ),
    },
    {
      title: "Society Code",
      dataIndex: "username",
      key: "username",
      render: (text, record) => {
        return record?.username ? record.username.toUpperCase() : "";
      },
    },
    { title: "Society Name", dataIndex: "name", key: "name" },
    {
      title: "Secretary",
      dataIndex: "secretary",
      key: "secretary",
      render: (text, record) =>
        record.secretary_name
          ? `${record.secretary_name} (${record.secretary_mobile_number})`
          : "N/A",
    },
    {
      title: "CSR",
      dataIndex: "district_officer",
      key: "csrNo",
      render: (text, record) =>
        record.district_officer
          .map((officer) => `${officer.name} (${officer.mobile_number})`)
          .join(", "),
    },
    {
      title: "Error Count",
      dataIndex: "latest_excel",
      key: "errorCount",
      render: (latest_excel) => {
        const countErrors = (errorField) => {
          return errorField ? JSON.parse(errorField).length : 0;
        };

        console.log("errors_length", latest_excel?.errors?.length);
        const totalErrors =
          countErrors(latest_excel?.errors) +
          countErrors(latest_excel?.secondary_level_validation) +
          countErrors(latest_excel?.third_level_validation);

        return totalErrors;
      },
    },

    {
      title: "Note",
      key: "note",
      render: (text, record) => (
        <Button
          onClick={() => showModal(record?.id)}
          style={{
            backgroundColor: "#1a98be",
            color: "white",
            cursor: "pointer",
          }}
        >
          Note
        </Button>
      ),
    },
  ];
  return (
    <div style={{ marginBottom: "10px" }}>
      {/* <Text
        style={{ fontWeight: "bold", fontSize: "20px", marginLeft: "10px" }}
      >
        {t("manual_check_pending")}
      </Text> */}

      <Collapse accordion>
        <Collapse.Panel
          header={
            <div>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginLeft: "10px",
                  color: "white",
                }}
              >
                {t("manual_check_pending")}
              </Text>
            </div>
          }
          style={{ backgroundColor: "#e67c21" }}
        >
          <Table
            columns={manual_check_columns}
            dataSource={manual_check_pending_society_details_list}
            pagination={false}
            loading={loading}
          />
          <HStack justifyContent={"flex-end"} marginTop={"10px"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              current={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ActionItemsManualCheckPendingTable;
