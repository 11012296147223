import { useDynamicSelector } from "@services/redux";
import { Modal, Spin } from "antd";
import { startCase } from "lodash";
import React, { useState, useEffect } from "react";

const FileUploadModalStatus = ({ is_modal_open }) => {
  const [loading, setLoading] = useState(true);
  const {
    status: user_excel_process_status,
    error: user_excel_process_error,
    is_completed,
  } = useDynamicSelector("getUserExcelProgress");

  useEffect(() => {
    setLoading(!is_completed); // Set loading to true if is_completed is false
  }, [is_completed]);

  return (
    <Modal
      title="File Upload Progress"
      visible={is_modal_open}
      footer={null}
      closable={false}
      maskClosable={false}
      centered
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>
          {startCase(
            user_excel_process_status ? user_excel_process_status : "Processing"
          )}
        </p>

        {loading ? <Spin size="large" /> : ""}
      </div>
    </Modal>
  );
};

export default FileUploadModalStatus;
