import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Button } from "antd";
import { retrieveItem } from "@helpers/storage";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ExcelToPdfConverter = ({ url }) => {
  const [excelUrl, setExcelUrl] = useState(url);
  const [loading, setLoading] = useState(false);
  const name = retrieveItem("name");
  const convertToPdf = async () => {
    setLoading(true);
    try {
      const response = await axios.get(excelUrl, {
        responseType: "arraybuffer",
      });
      const data = new Uint8Array(response.data);

      const workbook = XLSX.read(data, { type: "array" });
      const docDefinition = {
        content: [],
        pageSize: "A3",
        pageOrientation: "landscape",
        pageMargins: [40, 70, 40, 80],
        header: function (currentPage, pageCount) {
          return {
            text: `${name}`,
            alignment: "center",
            margin: [0, 10, 0, 0], // Adjust as needed
          };
        },
        footer: function (currentPage, pageCount) {
          return {
            columns: [
              {
                stack: [
                  {
                    text: "I/we have verified that the above data is correct and accurate as of 30-03-2023",
                    alignment: "left",
                    margin: [20, 0, 0, 10],
                    padding: [0, 0, 0, 20],
                  },
                  {
                    columns: [
                      {
                        text: "Secretary / President Signature",
                        alignment: "left",
                        margin: [20, 0, 0, 0],
                        padding: [0, 20, 0, 0],
                      },
                      {
                        text: "Administrator / CSR Signature",
                        alignment: "right",
                        margin: [0, 0, 20, 0],
                        padding: [0, 20, 0, 0],
                      },
                    ],
                    margin: [0, 30, 0, 0],
                  },
                ],
              },
            ],
          };
        },
      };

      // I/we have verified that the above data is correct and accurate as of 30-03-2023
      // Function to handle column rotation based on column width
      const rotateTextIfNeeded = (text, columnWidth) => {
        return text.length > columnWidth
          ? { text, style: { rotation: 45, alignment: "center" } }
          : text;
      };

      workbook.SheetNames.forEach((sheetName, index, array) => {
        if (index === 0 || index === array.length - 1) {
          return;
        }

        const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        if (excelData.length > 0) {
          const headerRow = Object.keys(excelData[0]).map((key) => key || " ");
          if (headerRow.length > 10) {
            const columnWidth = 15;
            // Split the header into chunks of 10
            const headerChunks = [];
            for (let i = 0; i < headerRow.length; i += 7) {
              if (i !== 0) {
                const chunk = headerRow.slice(i, i + 7);

                const chunkWithFirstTwoColumns = headerRow
                  .slice(0, 2)
                  .concat(chunk);
                headerChunks.push(chunkWithFirstTwoColumns);
              } else {
                const chunk = headerRow.slice(i, i + 7);
                headerChunks.push(chunk);
              }
            }
            headerChunks.forEach((headerChunk, chunkIndex, chunksArray) => {
              if (chunkIndex !== 0) {
                docDefinition.content.push({ text: "\n", pageBreak: "before" });
              }
              docDefinition.content.push({
                text: sheetName + ` (Part ${chunkIndex + 1})`,
                style: "header",
              });
              // const bodyRows = excelData.map((row) =>
              //   headerChunk.map((key) => {
              //     const cellText =
              //       row[key] !== undefined ? row[key].toString() : "";
              //     return rotateTextIfNeeded(cellText, columnWidth);
              //   })
              // );

              const bodyRows = excelData.map((row) =>
                headerChunk.map((key) => {
                  let cellText =
                    row[key] !== undefined ? row[key].toString() : "";

                  // Check if the key name indicates a date column (case-insensitive)
                  const isDateColumn =
                    /^(date|dob|doj|dor)|\b(date|dob|doj|dor)$/i.test(key);

                  if (isDateColumn) {
                    const numericValue = parseFloat(cellText);

                    // Check if the numeric value is a valid date
                    if (!isNaN(numericValue) && numericValue > 0) {
                      // Adjust for Excel epoch and set the year explicitly
                      const date = new Date(1900, 0, numericValue - 1);

                      // Check if the date is valid
                      if (!isNaN(date.getTime())) {
                        cellText = date.toLocaleDateString("en-GB"); // 'en-GB' for DD/MM/YYYY
                      } else {
                        // Handle cases where the numeric value does not correspond to a valid date
                        cellText = "";
                      }
                    } else {
                      // Handle cases where the numeric value is not a valid number
                      cellText = "";
                    }
                  }

                  return rotateTextIfNeeded(cellText, columnWidth);
                })
              );

              docDefinition.content.push({
                table: {
                  headerRows: 1,
                  widths: Array(headerChunk.length).fill("auto"),
                  body: [headerChunk, ...bodyRows],
                },
              });
              if (chunkIndex === chunksArray.length - 1) {
                console.log("chunksArray", chunksArray.length - 1, chunkIndex);

                docDefinition.content.push({ text: "\n", pageBreak: "after" });
              }
            });
            // docDefinition.footer = {
            //   columns: [
            //     {
            //       text: "Secretary / President Signature",
            //       alignment: "left",
            //       margin: [40, 20, 0, 0],
            //     },
            //     {
            //       text: "Administrator / CSR  Signature",
            //       alignment: "right",
            //       margin: [0, 20, 40, 0],
            //     },
            //   ],
            // };
          } else {
            const columnWidth = 15;
            // const bodyRows = excelData.map((row) =>
            //   headerRow.map((key) => {
            //     const cellText =
            //       row[key] !== undefined ? row[key].toString() : "";
            //     return rotateTextIfNeeded(cellText, columnWidth);
            //   })
            // );
            const bodyRows = excelData.map((row, index) =>
              headerRow.map((key) => {
                let cellText =
                  row[key] !== undefined ? row[key].toString() : "";

                const isDateColumn =
                  /^(date|dob|doj|dor)|\b(date|dob|doj|dor)$/i.test(key);
                const keysArray = Object.keys(row);
                const secondKey = keysArray[1];
                const secondKeyValue = row[secondKey];
                console.log("secondKeyValue", secondKeyValue, cellText);

                if (
                  (isDateColumn && sheetName !== "GL Accounts") ||
                  (sheetName === "Society Configuration" &&
                    secondKeyValue.toString() === cellText.toString() &&
                    (index === 1 || index === 2 || index === 14))
                ) {
                  const numericValue = parseFloat(cellText);

                  // Check if the numeric value is a valid date
                  if (!isNaN(numericValue) && numericValue > 0) {
                    // Adjust for Excel epoch and set the year explicitly
                    const date = new Date(1900, 0, numericValue - 1);

                    // Check if the date is valid
                    if (!isNaN(date.getTime())) {
                      cellText = date.toLocaleDateString("en-GB"); // 'en-GB' for DD/MM/YYYY
                    } else {
                      // Handle cases where the numeric value does not correspond to a valid date
                      cellText = "";
                    }
                  } else {
                    // Handle cases where the numeric value is not a valid number
                    cellText = "";
                  }
                }

                return rotateTextIfNeeded(cellText, columnWidth);
              })
            );

            docDefinition.content.push({
              text: sheetName,
              style: "header",
            });
            docDefinition.content.push({
              table: {
                headerRows: 1,
                widths: Array(headerRow.length).fill("auto"),
                body: [headerRow, ...bodyRows],
              },
            });
            console.log("docDefinition.footer1", docDefinition.footer);

            console.log("docDefinition.footer2", docDefinition.footer);

            docDefinition.content.push({ text: "\n", pageBreak: "after" });
          }
        }
      });
      // docDefinition.footer = {
      //   columns: [
      //     {
      //       text: "Secretary / President Signature",
      //       alignment: "left",
      //       margin: [40, 20, 0, 0],
      //     },
      //     {
      //       text: "Administrator / CSR  Signature",
      //       alignment: "right",
      //       margin: [0, 20, 40, 0],
      //     },
      //   ],
      // };

      const pdf = pdfMake.createPdf(docDefinition);

      pdf.download(`${name}.pdf`);
    } catch (error) {
      console.error("Error converting Excel to PDF:", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <Button
        onClick={convertToPdf}
        loading={loading}
        type="primary"
        style={{
          height: "45px",
        }}
      >
        Download File
      </Button>
    </div>
  );
};

export default ExcelToPdfConverter;
