import React, { useEffect, useState } from "react";
import logo from "@assets/icons/logo.png";

import {
  HStack,
  VStack,
  Image,
  Center,
  Hidden,
  Stack,
  Box,
  Text,
} from "native-base";
import image from "@assets/images/logo.png";
import LoginForm from "./login_form";
import ForgotPasswordForm from "./forgot_password_form";
import { ProjectName } from "@helpers/constants";
import society from "@assets/images/society-image.png";

const Login = (props) => {
  const [_showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
  const [_showLoginForm, setShowLoginForm] = useState(true);

  const showForgotPasswordForm = (value1) => {
    setShowForgotPasswordForm(value1);
  };
  const showLoginForm = (value3) => {
    setShowLoginForm(value3);
  };

  useEffect(() => {
    if (props.type === "force_password") {
      setShowForgotPasswordForm(true);
      setShowLoginForm(false);
    } else {
      setShowLoginForm(true);
      setShowForgotPasswordForm(false);
    }
  }, [props.type]);

  return (
    <>
      <Box
        safeAreaTop
        _light={{
          bg: "primary.900",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center
        my="auto"
        _dark={{
          bg: "#e1e7fa",
        }}
        _light={{
          bgImage: `url(${society})`,

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",
          opacity: "1",
        }}
        flex="1"
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "1016px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <VStack px="2" mt="2" space="9" flex="1">
              {/* <HStack alignItems="center"> */}
              <Image
                cursor={"pointer"}
                w={"180px"}
                h="80px"
                // size="180px"
                alt="logo"
                resizeMode={"contain"}
                source={logo}
              />
              {/* </HStack> */}
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="#056cb4"
              opacity="0.9"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <img
                height="200px"
                size="75"
                alt="NativeBase Startup+ "
                resizeMode={"contain"}
                src={image}
              />

              <HStack justifyContent={"center"} alignItems="center">
                <Text
                  color={"white"}
                  bold
                  fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
                  justifyContent="center"
                  display="flex"
                  fontWeight="normal"
                >
                  {ProjectName}
                </Text>
              </HStack>
            </Center>
          </Hidden>
          <Hidden from="md">
            <HStack justifyContent={"center"} alignItems="center" mb={4}>
              <Text
                color={"#396591"}
                bold
                fontSize={{ base: 30, xs: 30, sm: 20, md: 25 }}
                justifyContent="center"
                display="flex"
                fontWeight="normal"
              >
                {ProjectName}
              </Text>
            </HStack>
          </Hidden>
          {_showLoginForm && !_showForgotPasswordForm && (
            <LoginForm
              props={props}
              showForgotPasswordForm={showForgotPasswordForm}
              showLoginForm={showLoginForm}
            />
          )}
          {_showForgotPasswordForm && !_showLoginForm && (
            <ForgotPasswordForm
              showForgotPasswordForm={showForgotPasswordForm}
              showLoginForm={showLoginForm}
            />
          )}
        </Stack>
      </Center>
    </>
  );
};

export default Login;
