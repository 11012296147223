import LoginComponent from "@views/components/auth/login";
import { Box } from "native-base";
import React, { useEffect } from "react";
import { navigate } from "@helpers/navigator";
import { useCheckLogin } from "@helpers/auth";

const Login = () => {
  const is_logged_in = useCheckLogin();
  useEffect(() => {
    if (is_logged_in === true) {
      navigate("/");
    }
  }, [is_logged_in]);

  return (
    <Box flex={1}>
      <LoginComponent />
    </Box>
  );
};
export default Login;
