import { dynamicClear, useDynamicSelector } from "@services/redux";
import { Button } from "antd";
import { Box } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const FileDownloadDetails = (props) => {
  const { setDownloadModalOpen } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    id: latest_upload_id,
    excel_url,
    loading: latest_upload_download_loading,
  } = useDynamicSelector("getUserLatestExcel");

  const handleModalClose = () => {
    setDownloadModalOpen(false);
    dispatch(dynamicClear("getUserLatestExcel"));
  };
  return (
    <>
      <Box style={{ padding: "10px" }}>
        {/* {!latest_upload_id
         ? (
          <text>{t("download_detail")}</text>
        ) : (
          <text>{t("no_file_found")}</text>
        )} */}

        <text>{t("no_file_found")}</text>
      </Box>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          padding: "10px",
        }}
      >
        {/* {latest_upload_id ? (
          <Button
            style={{
              height: "35px",
              width: "85px",
              backgroundColor: "#1677ff",
              color: "white",
            }}
            onClick={() => {
              window.open(excel_url);
              // setDownloadModalOpen(false);
              handleModalClose();
            }}
            color="#158d94"
            loading={latest_upload_download_loading}
          >
            {t("download")}
          </Button>
        ) : ( */}
        <Button
          style={{
            height: "35px",
            width: "85px",
            backgroundColor: "#1677ff",
            color: "white",
          }}
          onClick={handleModalClose}
          color="#158d94"
        >
          {t("back")}
        </Button>
        {/* )} */}
      </Box>
    </>
  );
};

export default FileDownloadDetails;
