import React, { useState, useRef } from "react";
import { Box, useBreakpointValue } from "native-base";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

let defaultStyles = {
  burgerColor: "#7199ff",
  backColor: "#ff4a4a",
  headerHeight: "200px",
  footerHeight: "100px",
  titleColor: "#adadad",
  titleHoverColor: "#ffffff",
  titleActiveColor: "#ffffff",
  iconShape: "square",
  iconBgColor: "#2b2b2b",
  iconColor: "#ff0000",
  iconSize: 15,
  iconActiveColor: "#ffffff",
};

const SideBar = (props) => {
  let sidebarToggle = localStorage.getItem("sidebarToggle");
  const [open, setOpen] = useState([]);

  const isCollapsible = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: true,
    lg: false,
    xl: false,
  });

  const location = useLocation();

  let burgerColor = props.burgerColor || defaultStyles.burgerColor;
  let backColor = props.backColor || defaultStyles.backColor;
  let headerHeight = props.headerHeight || defaultStyles.headerHeight;
  let footerHeight = props.footerHeight || defaultStyles.footerHeight;
  let titleColor = props.titleColor || defaultStyles.titleColor;
  let titleHoverColor = props.titleHoverColor || defaultStyles.titleHoverColor;
  let titleActiveColor =
    props.titleActiveColor || defaultStyles.titleActiveColor;
  let iconShape = props.iconShape || defaultStyles.iconShape;
  let iconBgColor = props.iconBgColor || defaultStyles.iconBgColor;
  let iconColor = props.iconColor || defaultStyles.iconColor;
  let iconSize = props.iconSize || defaultStyles.iconSize;
  const stylesheet = document.styleSheets[1];
  const boxParaRule = [...stylesheet.cssRules].find(
    (r) => r.selectorText === ".box p"
  );
  document.documentElement.style.setProperty(
    "--sidebar-title-color",
    titleColor
  );
  document.documentElement.style.setProperty(
    "--sidebar-title-hover-color",
    titleHoverColor
  );
  document.documentElement.style.setProperty(
    "--sidebar-title-active-color",
    titleActiveColor
  );
  const setOpenMenu = (menu) => {
    let data = [];
    menu?.map((x) => {
      if (x.children) {
        let temp = setOpenMenu(x.children);
        if (temp.length) {
          data.push(...temp, x.key);
        }
      }
      if ("/" + x.key === location.pathname) {
        data.push(x.key);
      }
    });
    return data;
  };

  const Header = (itemProps) => {
    if (props.headerComponent) {
      const element = React.cloneElement(props.headerComponent, {
        ...itemProps,
      });
      return <>{element}</>;
    }
    return <></>;
  };

  const Footer = (itemProps) => {
    if (props.headerComponent) {
      const element = React.cloneElement(props.footerComponent, {
        ...itemProps,
      });
      return <>{element}</>;
    }
    return <></>;
  };

  const handleMenu = (menu) => {
    return menu?.map((x) => ({
      key: x.key,
      icon: x.icon,
      label: <Link to={x.url}>{x.title}</Link>,
      children: handleMenu(x.children),
    }));
  };
  return (
    <>
      <Box w={isCollapsible ? "350px" : "17vw"} h={"100%"}>
        <Box w="100%" h={headerHeight}>
          <Header />
        </Box>
        <Box flex={1} style={{}} mt="16px">
          <Menu
            defaultSelectedKeys={[location.pathname.split("/")[1]]}
            defaultOpenKeys={setOpenMenu(props.menu)}
            mode="inline"
            items={handleMenu(props.menu)}
            onSelect={(e) => {}}
            style={{
              flex: 1,
              background: `-webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("${props.image}")`,
            }}
          />
        </Box>

        <Box w="100%" h={footerHeight}>
          <Footer />
        </Box>
      </Box>
    </>
  );
};
export default SideBar;
