import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Center } from "native-base";
import Loader from "react-js-loader";

import DashboardDetails from "@views/components/dashboard/dashboard";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [loading, setCurrent] = useState(false);

  return (
    <>
      {loading ? (
        <Center mt={"45vh"}>
          <Loader
            type="spinner-default"
            bgColor={"#349481"}
            color={"#349481"}
            size={50}
          />
        </Center>
      ) : (
        <DashboardDetails />
      )}
    </>
  );
};
export default Dashboard;
