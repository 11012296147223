import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Center, HStack, Text } from "native-base";
import toast from "react-hot-toast";
import {
  dynamicClear,
  dynamicRequest,
  additional_excel_import_mutation,
  useDynamicSelector,
} from "@services/redux";

const AdditionalFileUploadDetails = (props) => {
  const { t } = useTranslation();
  const { form, close, getAdditionalUserExcel } = props;
  const dispatch = useDispatch();
  const { status, error, loading } = useDynamicSelector(
    "additional_excel_import"
  );

  useEffect(() => {
    if (status === "Success") {
      toast.success("Excel processing started please check after some time");
      close();
      getAdditionalUserExcel(1, 10);
      dispatch(dynamicClear("additional_excel_import"));
    } else if (error?.message) {
      dispatch(dynamicClear("additional_excel_import"));
      close();
      toast.error("Something went wrong please try again");
    }
  }, [error, status]);

  const handleSubmit = (values) => {
    let process_excel_key = [{ key: "additional_excel_import", loading: true }];
    let process_excel_variables = {
      file: values.file[0].url,
    };

    dispatch(
      dynamicRequest(
        process_excel_key,
        additional_excel_import_mutation,
        process_excel_variables
      )
    );
  };

  return (
    <Box flex={1}>
      <Form
        form={form}
        name={"file_upload"}
        layout={"vertical"}
        onFinish={handleSubmit}
      >
        <Box flex={1} mt="6">
          <VForm.File
            label={t("form:file")}
            field="file"
            rules={[
              {
                required: true,
                message: t("error:upload_file_required"),
              },
            ]}
          />
        </Box>

        <HStack space="10" justifyContent={"flex-end"}>
          <VForm.Button isLoading={loading}>{t("Submit")}</VForm.Button>
          <Button onClick={close}>{t("close")}</Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default AdditionalFileUploadDetails;
