import React from "react";
import { ROUTES } from "@views/routes/my_routes";
import { Box, Hidden, VStack } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import HeaderBar from "@views/components/ui/header_bar";
import FooterBar from "@views/components/ui/footer_bar";
import SideBar from "@views/components/ui/side_bar";
import SideBarHeader from "@views/components/modules/sidebar/sidebar_header";
import SideBarFooter from "@views/components/modules/sidebar/sidebar_footer";
import { FcAreaChart } from "react-icons/fc";
import { retrieveItem } from "@helpers/storage";
import { TbBuildingBank } from "react-icons/tb";
import { RxDashboard } from "react-icons/rx";
import { RiFolderUploadLine } from "react-icons/ri";
import { AiOutlineSlack } from "react-icons/ai";

const Layout1 = ({ children }) => {
  const role = retrieveItem("role");

  const society_menu = [
    {
      key: "file_upload",
      title: "Upload",
      icon: <RiFolderUploadLine size={28} />,
      url: ROUTES.FILE_UPLOAD_DETAILS,
    },
    {
      key: "additional_file_upload",
      title: "Additional Upload",
      icon: <RiFolderUploadLine size={28} />,
      url: ROUTES.ADDITIONAL_FILE_UPLOAD,
    },
  ];

  const admin_menu = [
    {
      key: "dashboard",
      title: "Dashboard",
      icon: <RxDashboard size={26} />,
      url: ROUTES.DASHBOARD,
    },
    {
      key: "society",
      title: "Society",
      icon: <TbBuildingBank size={28} />,
      url: ROUTES.SOCIETY_PAGE,
    },
  ];

  const zone_menu = [
    {
      key: "dashboard",
      title: "Dashboard",
      icon: <RxDashboard size={26} />,
      url: ROUTES.DASHBOARD,
    },
    {
      key: "society",
      title: "Society",
      icon: <TbBuildingBank size={28} />,
      url: ROUTES.SOCIETY_PAGE,
    },
    {
      key: "action_items",
      title: "Action Items",
      icon: <AiOutlineSlack size={28} />,
      url: ROUTES.ACTION_ITEMS,
    },
  ];

  const getMenu = () => {
    switch (role) {
      case "Admin":
      case "district_admin":
      case "rcs":
      case "mdccb":
        return admin_menu;
      case "Zone Admin":
        return zone_menu;
      default:
        return society_menu;
    }
  };

  const menu = getMenu();

  return (
    <Box flexDirection="row" flexGrow="1">
      <Hidden till="md">
        <SideBar
          image="https://m.media-amazon.com/images/I/61JSTnKo9iL._SX679_.jpg"
          iconShape="round"
          iconBgColor="none"
          iconColor="#ffffff"
          iconSize={20}
          iconSelectedColor="#330000"
          titleColor="#adadad"
          titleActiveColor="#ffffff"
          headerHeight="63px"
          footerHeight="50px"
          menu={menu}
          headerComponent={<SideBarHeader />}
          footerComponent={<SideBarFooter />}
        />
      </Hidden>
      <VStack flex={1}>
        <HeaderBar menu={menu} />
        <ScrollView flex={1}>
          <Box flex={1} ml="10px" mr="10px">
            {children}
          </Box>
        </ScrollView>
        <FooterBar />
      </VStack>
    </Box>
  );
};

export default Layout1;
