import { create_note_mutation } from "@services/redux";
import { Button, Form, Input, Modal, Pagination, Table } from "antd";
import { HStack } from "native-base";
import React from "react";

const ActionItemsCustomModal = ({
  isVisible,
  onOk,
  onCancel,
  columns,
  data,
  onFinish,
  form,
  fetchData,
  page_number,
  pagination,
}) => {
  return (
    <Modal
      title="Note Details"
      visible={isVisible}
      onOk={onOk}
      onCancel={onCancel}
      width={1200}
      footer={null}
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 280 }}
      />
      <HStack justifyContent={"flex-end"} marginTop={"10px"}>
        <Pagination
          total={pagination?.total_count}
          showSizeChanger={true}
          current={page_number}
          hideOnSinglePage={false}
          onChange={fetchData}
        />
      </HStack>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        style={{
          marginTop: 16,
          display: "flex",
          flexDirection: "row",
          // height: 150,
        }}
      >
        <div style={{ width: "90%" }}>
          <Form.Item
            name="note"
            label="Note"
            style={{ width: "97%" }}
            rules={[{ required: true, message: "Please input your note!" }]}
          >
            <Input.TextArea rows={1} />
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button key="submit" type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ActionItemsCustomModal;
