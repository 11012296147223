import AdditionalFileUploadList from "@views/components/additional_file_upload_details/additional_file_upload_list";
import { Box } from "native-base";
import React from "react";

const AdditionalFileUploadListPage = () => {
  return (
    <Box>
      <AdditionalFileUploadList />
    </Box>
  );
};

export default AdditionalFileUploadListPage;
