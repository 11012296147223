import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack, Pressable, Modal } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Wrapper from "@views/components/ui/wrapper";
import { Button, Pagination, Select, Table } from "antd";
import { showToast } from "@helpers/toast";
import SearchBox from "../ui/search_box/search_box";
import AntdModal from "../ui/antd_modal/antd_modal";
import OfficerDetails from "./officer_details";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

const OfficerList = () => {
  const dispatch = useDispatch();
  const [action_item, setActionItem] = useState({});
  const [dialog_visible, setDialogVisible] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const { t } = useTranslation();
  const [model_open, setModalOpen] = useState(false);
  const [action_type, setActionType] = useState(null);
  const [search, setSearch] = useState("");
  const [district_value, setDistrictValue] = useState("");
  const data = [];
  const name = ["Saran", "Rakesh", "Rakshitha", "Abi", "Arun"];

  const district = ["Ariyalur", "Erode", "Chennai", "Karur", "Trichy"];
  for (let i = 0; i < 5; i++) {
    data.push({
      name: name[i],
      designation: "Office Admin",
      district: district[i],
    });
  }
  const handleSearch = (search_txt) => {
    setSearch(search_txt);
  };

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };
  const handleEdit = (record) => {
    setActionItem(record);
    setActionType("edit");
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setActionItem();
    setDialogVisible(true);
  };
  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      width: "40%",
    },
    {
      title: t("table:designation"),
      dataIndex: "designation",
      key: "designation",
      sortable: false,
      align: "left",

      width: "30%",
    },
    {
      title: t("table:district"),
      dataIndex: "district",
      key: "district",
      sortable: false,
      align: "left",

      width: "30%",
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <HStack space="5" justifyContent={"center"}>
            <Pressable
              onPress={() => {
                handleEdit(record);
              }}
            >
              <AiOutlineEdit color="#158d94" size="20" />
            </Pressable>
            <Pressable
              onPress={() => {
                handleDeleteModalOpen(record);
              }}
            >
              <AiOutlineDelete color="#ef0303e0" size="20" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  return (
    <>
      <Box top="10px" zIndex="2" mb="10px">
        <Text fontSize="md" fontWeight="bold">
          {t("table:officer")}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          {/* <Box width="300px">
            <Text bold mb="2px">
              District
            </Text>
            <Select
              style={{ width: "100%" }}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
              onChange={(e) => {
                setDistrictValue(e);
              }}
            >
              {district_list?.map((item) => {
                return <Option value={item.value}>{item.name}</Option>;
              })}
            </Select>
          </Box> */}
          <HStack space={3} justifyContent="flex-end">
            <Box key={`header-action`}>
              <Button
                style={{
                  height: "45px",
                }}
                onClick={handleAdd}
                color="#158d94"
                background={"#158d94"}
              >
                Add Officer
              </Button>
            </Box>
            <Box>
              <SearchBox
                label={t("table:search")}
                width={{
                  base: "300px",
                  xs: "200px",
                  sm: "200px",
                  md: "300px",
                }}
                onSearch={handleSearch}
              />
            </Box>
          </HStack>
          <Wrapper>
            <Table
              //   loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={data?.length}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={model_open}
        onClose={handleModalClose}
        width={450}
        header={action_type === "add" ? "Add Officer" : "Edit Officer"}
        component={
          <OfficerDetails
            label={action_type === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={action_item}
          />
        }
      />
    </>
  );
};
export default OfficerList;
