import React from "react";
import { Box, Hidden, HStack, Image, Text } from "native-base";
import logo from "@assets/icons/logo.png";

const FooterBar = (props) => {
  return (
    <Box w="100%" bg="#ffffff" position="relative">
      <HStack h="100%" justifyContent="space-between" marginY={"2"} mr="2">
        <Hidden from="lg">
          <Image
            cursor={"pointer"}
            w={"80px"}
            h="20px"
            // size="180px"
            alt="logo"
            resizeMode={"contain"}
            source={logo}
          />
        </Hidden>
        <Hidden till="lg">
          <></>
        </Hidden>

        <Text>Copyright © {new Date().getFullYear()}</Text>
      </HStack>
    </Box>
  );
};

export default FooterBar;
