import React from "react";
import { useTranslation } from "react-i18next";

const ActionItemsTitle = () => {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <h2>{t("action_items")}</h2>
    </div>
  );
};

export default ActionItemsTitle;
