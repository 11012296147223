import { amountFormatWithSymbolAndColor } from "@helpers/constants";
import { Box, Divider, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const InvestmentDetails = (props) => {
  const { t } = useTranslation();
  const { investment_group_array } = props;
  return (
    <>
      <>
        <Box marginTop={"10px"}>
          <Text bold fontSize="16px">
            {t("investment_details")}
          </Text>
        </Box>
        <table bordered>
          <thead>
            <tr>
              <th>{t("gl_account")}</th>
              <th>{t("amount_in_gl")}</th>
              <th>{t("investment_sheet")}</th>
              <th>{t("result")}</th>
            </tr>
          </thead>

          <tbody>
            {investment_group_array?.map((x) => {
              return (
                <tr>
                  <td>{x.gl_account}</td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormatWithSymbolAndColor(x.amount_in_gl)}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormatWithSymbolAndColor(x.total_amount)}
                  </td>
                  <td
                  // style={{
                  //   textAlign: "right",
                  // }}
                  >
                    <Text
                      color={
                        x.amount_match?.toLowerCase().includes("mismatched")
                          ? "red.500"
                          : "green.600"
                      }
                    >
                      {x.amount_match}
                    </Text>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Box>
          {investment_group_array?.map((x) => {
            if (x.amount_match?.toLowerCase().includes("mismatched")) {
              return (
                <Text>
                  The total of respective amounts in{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    "Amount"
                  </span>{" "}
                  column in the{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    "Investments"
                  </span>{" "}
                  sheet must match with {""}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    "{x.gl_account}" {""}
                  </span>
                  value in the{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    "GL Account" {""}
                  </span>{" "}
                  sheet
                </Text>
              );
            }
          })}
        </Box>

        <Divider />
      </>
    </>
  );
};

export default InvestmentDetails;
