import React from "react";
import SocietyList from "./society_list";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { Box, Text } from "native-base";
import AdditionalDataSocietyList from "./additional_data_society_list";

const society_tab = () => {
  const { t } = useTranslation();

  const [index, setIndex] = React.useState(0);

  let tab_items = [
    {
      id: 1,
      label: t("Society upload list"),
      key: 1,
      children: <SocietyList file_type={"basic_information"} />,
    },
    {
      id: 2,
      label: t("Society Additional upload list"),
      key: 2,
      children: (
        <AdditionalDataSocietyList file_type={"additional_information"} />
      ),
    },
  ];
  return (
    <div>
      <Box top="10px" zIndex="2" mb={"10"}>
        <Text fontSize="md" fontWeight="bold">
          {t("society")}
        </Text>
      </Box>
      <Tabs
        items={tab_items}
        index={index}
        defaultActiveKey={index}
        key={index}
      />
    </div>
  );
};

export default society_tab;
