import {
  bridge_mutation,
  dynamicRequest,
  get_one_society_query,
  secondary_level_validation,
  useDynamicSelector,
  user_excel_query,
} from "@services/redux";
import { Button, Card, Pagination } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import LazyLoad from "react-lazy-load";
import { retrieveItem } from "@helpers/storage";

const SecondaryLevelValidation = (props) => {
  const role = retrieveItem("role");
  const history = useHistory();
  const { t } = useTranslation();
  const { id, society_id } = useParams();
  const [errors, setErrors] = useState([]);
  const [error_count, setErrorCount] = useState(0);
  const [status, setStatus] = useState("");
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);

  const data = useDynamicSelector("user_excel");
  const { loading } = useDynamicSelector("push_to_bridge");
  const { loading: cbs_loading } = useDynamicSelector("dataMigrate");
  const { name, username } = useDynamicSelector("getSociety");

  const dispatch = useDispatch();

  useEffect(() => {
    getUserExcel(id);
  }, [id]);

  useEffect(() => {
    getSociety();
  }, [society_id]);
  const getSociety = () => {
    let society_key = [{ key: "getSociety", loading: true }];
    let society_variables = {
      id: society_id,
    };
    dispatch(
      dynamicRequest(society_key, get_one_society_query, society_variables)
    );
  };
  // useEffect(() => {
  //   if (data?.id) {
  //     let temp = [];
  //     if (data.secondary_level_validation) {
  //       temp = JSON.parse(data.secondary_level_validation);
  //       console.log("temp", temp);
  //       setErrorCount(temp.length);
  //       setErrors(temp);
  //     }
  //   }
  // }, [data]);

  useEffect(() => {
    if (data?.id) {
      let temp = [];
      if (data.secondary_level_validation) {
        temp = JSON.parse(data.secondary_level_validation);
        console.log("temp", temp);
        setErrorCount(temp.length);

        setErrors(temp.slice((current - 1) * limit, current * limit));
      }
    }
  }, [data, current, limit]);

  const getUserExcel = (id) => {
    let user_excel_key = [{ key: "user_excel", loading: true }];
    let user_excel_variables = {
      id: id,
    };
    dispatch(
      dynamicRequest(
        user_excel_key,
        secondary_level_validation,
        user_excel_variables
      )
    );
  };

  const handleBack = () => {
    history.goBack();
  };
  const fetchData = (page_number, page_limit) => {
    setCurrent(page_number);
    setLimit(page_limit);
  };

  const handleBridgePush = () => {
    let bridge_key = [{ key: "push_to_bridge", loading: true }];
    let bridge_variables = {
      id,
    };
    dispatch(
      dynamicRequest(bridge_key, bridge_mutation, bridge_variables, "M")
    );
  };

  const handleCBSPush = () => {
    let cbs_key = [{ key: "dataMigrate", loading: true }];
    let cbs_variables = {
      society_id: id,
    };
    dispatch(dynamicRequest(cbs_key, cbs_mutation, cbs_variables, "M"));
  };

  return (
    <Box flex={1} padding="5">
      {data.loading ? (
        <Loader
          type="spinner-default"
          bgColor={"#349481"}
          color={"#349481"}
          size={50}
        />
      ) : (
        <Box>
          <HStack justifyContent="flex-end">
            <Button type="default" danger onClick={handleBack}>
              Back
            </Button>
          </HStack>
          {role !== "Society" && (
            <Box top="10px" zIndex="2" mb="10px">
              <Text fontSize="md" fontWeight="bold">
                {name} - {username}
              </Text>
            </Box>
          )}
          <Text bold fontSize={"md"}>
            Response
          </Text>
          <Box marginTop="2">
            <Card
              style={{
                alignItems: "center",
              }}
            >
              <HStack flex="1">
                <Box flex="1">
                  <VStack space={"5"}>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:uploaded_date_time")} : </Text>
                      </Box>
                      <Box>
                        <Text>
                          {data.uploaded_time
                            ? moment(new Date(Number(data.uploaded_time)))
                                .utcOffset("+05:30")
                                .format("DD/MM/YY hh:mm:ss a")
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:status")} : </Text>
                      </Box>
                      <Box>
                        <Text>{status} </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:error_count")} : </Text>
                      </Box>
                      <Box>
                        <Text>
                          {error_count > 0 ? (
                            error_count
                          ) : (
                            <div>
                              We are analyzing your file and we will let you
                              know if there are any errors.
                              <br />
                              உங்களது தகவல்கள் சரிபார்க்கபடுகின்றன. ஏதேனும்
                              தவறுகள் இருப்பின் உங்களுக்கு தெரிவிக்கபடும்.
                            </div>
                          )}
                        </Text>
                      </Box>
                    </HStack>
                  </VStack>
                </Box>
                <Box flex="1">
                  {/* <Center my={"auto"}>
                    {status === "Valid File" && role === "Admin" ? (
                      <>
                        <Button
                          type="primary"
                          onClick={handleBridgePush}
                          loading={loading}
                        >
                          Push to Bridge
                        </Button>
                        <Button
                          type="primary"
                          onClick={handleCBSPush}
                          loading={cbs_loading}
                        >
                          Push to CBS
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Center> */}
                </Box>
              </HStack>
            </Card>
          </Box>
          {console.log("error_count", error_count)}
          <VStack>
            {errors?.map((x, i) => (
              <div key={i}>
                {console.log("sowmi", x)}
                <div className="sticky-name">
                  <Center>
                    <Text bold>{x.sheet_name} </Text>
                  </Center>
                  <Center>
                    <Text bold>Row Number: {x.row_number} </Text>
                  </Center>
                </div>
                <LazyLoad offset={1200} key={i}>
                  <div key={i}>
                    <Box padding="3%">
                      <HStack>
                        <Box width="30%">
                          <Text bold>{t("table:column_name")} : </Text>
                        </Box>
                        <Box flex={1}>
                          <Text>{x?.column_name} </Text>
                        </Box>
                      </HStack>
                      <HStack flex={1}>
                        <Box width="30%">
                          {console.log("xxxx", x)}

                          <Text bold>{t("table:error_type")} : </Text>
                        </Box>
                        <Box flex={1}>
                          <Text>{x.error_type} </Text>
                        </Box>
                      </HStack>

                      <HStack>
                        <Box width="30%">
                          <Text bold>{t("table:error_message")} : </Text>
                        </Box>
                        <Box flex={1}>
                          <Text>{x.error}</Text>
                        </Box>
                      </HStack>
                    </Box>
                  </div>
                </LazyLoad>
              </div>
            ))}
          </VStack>
          {error_count ? (
            <Pagination
              total={error_count}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              onChange={fetchData}
              onShowSizeChange={fetchData}
            />
          ) : (
            ""
          )}
        </Box>
      )}
    </Box>
  );
};
export default SecondaryLevelValidation;
