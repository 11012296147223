import { useDynamicSelector } from "@services/redux";
import { capitalize } from "lodash";
import { Box } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CbsPushResponseDetails = () => {
  const { t } = useTranslation();
  const CBS_response_list = useDynamicSelector("getCBSMigrationLogs");

  const [response, set_response] = useState(null);

  useEffect(() => {
    if (CBS_response_list?.logs) {
      const parsedLogs = JSON.parse(CBS_response_list.logs);
      set_response(parsedLogs);
    }
  }, [CBS_response_list]);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          padding: "5px",
          marginBottom: "10px",
        }}
      >
        <span style={{ fontWeight: "bold" }}>
          {CBS_response_list.logs === null && "Processing"}
        </span>
      </Box>
      {response && (
        <Box
          style={{
            maxHeight: "380px",
            overflowY: "auto",
          }}
        >
          {response.map(
            (log, index) =>
              log && (
                <Box
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ marginBottom: "5px" }}>
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Table:
                    </span>
                    <span style={{ fontSize: "11px", marginLeft: "5px" }}>
                      {log?.table && capitalize(log.table.replace(/_/g, " "))}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Response:
                    </span>
                    <span style={{ fontSize: "11px", marginLeft: "5px" }}>
                      {log?.response?.length ? "Failure" : "Success"}
                    </span>
                    <hr style={{ border: "1px solid lightgray" }} />
                  </div>
                </Box>
              )
          )}
        </Box>
      )}
    </Box>
  );
};

export default CbsPushResponseDetails;
