import OfficerList from "@views/components/officer/officer_list";
import { Box } from "native-base";
import React from "react";

const OfficerPage = () => {
  return (
    <Box>
      <OfficerList />
    </Box>
  );
};

export default OfficerPage;
