import { gql } from "@apollo/client";

export const get_live_society_details_query = gql`
  query getSocieties(
    $search_string: String
    $district_id: String
    $status: String
    $page_number: Int
    $page_limit: Int
    $from_date: String
    $to_date: String
    $is_second_level_validated: Boolean
  ) {
    get_live_society_details: getSocieties(
      search_string: $search_string
      district_id: $district_id
      status: $status
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
      is_second_level_validated: $is_second_level_validated
    ) {
      items {
        id
        username
        name
        secretary_name
        secretary_mobile_number
        district_id
        current_accounting_day
        district {
          id
          name
        }
        district_officer {
          name
          mobile_number
        }
        error {
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_test_credentials_society_details_query = gql`
  query getSocieties(
    $search_string: String
    $district_id: String
    $status: String
    $page_number: Int
    $page_limit: Int
    $from_date: String
    $to_date: String
    $is_second_level_validated: Boolean
  ) {
    get_test_credentials_society_details: getSocieties(
      search_string: $search_string
      district_id: $district_id
      status: $status
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
      is_second_level_validated: $is_second_level_validated
    ) {
      items {
        id
        username
        name
        secretary_name
        secretary_mobile_number
        district_id
        test_credentials_given_date
        district {
          id
          name
        }
        district_officer {
          name
          mobile_number
        }
        error {
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_backlog_entry_society_details_query = gql`
  query getSocieties(
    $search_string: String
    $district_id: String
    $status: String
    $page_number: Int
    $page_limit: Int
    $from_date: String
    $to_date: String
    $is_second_level_validated: Boolean
  ) {
    get_backlog_entry_society_details: getSocieties(
      search_string: $search_string
      district_id: $district_id
      status: $status
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
      is_second_level_validated: $is_second_level_validated
    ) {
      items {
        id
        username
        name
        secretary_name
        secretary_mobile_number
        district_id
        current_accounting_day
        district {
          id
          name
        }
        district_officer {
          name
          mobile_number
        }
        error {
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_manual_check_pending_society_details_query = gql`
  query getSocieties(
    $search_string: String
    $district_id: String
    $status: String
    $page_number: Int
    $page_limit: Int
    $from_date: String
    $to_date: String
    $is_second_level_validated: Boolean
  ) {
    get_manual_check_pending_society_details: getSocieties(
      search_string: $search_string
      district_id: $district_id
      status: $status
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
      is_second_level_validated: $is_second_level_validated
    ) {
      items {
        id
        username
        name
        secretary_name
        secretary_mobile_number
        district_id
        latest_excel {
          errors
          secondary_level_validation
          third_level_validation
        }
        district {
          id
          name
        }
        district_officer {
          name
          mobile_number
        }
        error {
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_validated_society_details_query = gql`
  query getSocieties(
    $search_string: String
    $district_id: String
    $status: String
    $page_number: Int
    $page_limit: Int
    $from_date: String
    $to_date: String
    $is_second_level_validated: Boolean
  ) {
    get_validated_society_details: getSocieties(
      search_string: $search_string
      district_id: $district_id
      status: $status
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
      is_second_level_validated: $is_second_level_validated
    ) {
      items {
        id
        username
        name
        secretary_name
        secretary_mobile_number
        district_id
        district {
          id
          name
        }
        district_officer {
          name
          mobile_number
        }
        error {
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_zone_admin_dashboard_query = gql`
  query getSuperAdminDashboard($district_list: [String]) {
    get_zone_admin_dashboard: getSuperAdminDashboard(
      district_list: $district_list
    ) {
      total_society
      not_started_process
      live_society_count
      testing_society_count
      uploaded_without_error_count
      uploaded_with_error_count
      backlog_entry_count
    }
  }
`;
export const get_notes_query = gql`
  query getNotes($society_id: String, $page_number: Int, $page_limit: Int) {
    getNotes(
      society_id: $society_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        description
        created_datetime
        created_by {
          name
        }
        created_by_id
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_note_mutation = gql`
  mutation createNote($data: note_input) {
    createNote(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
