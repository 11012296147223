import { gql } from "@apollo/client";
import { QueryRequest } from "@services/apollo/api_service";
import { fileQuery } from "@services/redux";
import axios from "axios";

export default async function fileUpload(
  file,
  file_name,
  storageName,
  question_id,
  user_id
) {
  let url = await getURL(storageName, String(file_name), question_id, user_id);
  let final_url = url?.split("?")[0];
  let success = true;
  await axios
    .put(url, file, {
      headers: { "Content-Type": file?.type },
    })
    .then(async (res) => {
      if (res.status.toString() === "200") {
        // return final_url;
      } else {
        success = false;
      }
    });
  if (success) {
    return final_url;
  }
}
async function getURL(type, file_name, question_paper_id, user_id) {
  let query = gql`
    query getUploadUrl(
      $type: String
      $question_paper_id: String
      $file_name: String
      $user_id: String
    ) {
      getUploadUrl(
        type: $type
        question_paper_id: $question_paper_id
        file_name: $file_name
        user_id: $user_id
      ) {
        status
        url
        error {
          status_code
          message
        }
      }
    }
  `;

  let { data } = await QueryRequest(query, {
    type,
    file_name,
    question_paper_id,
    user_id,
  });
  return data.getUploadUrl.url;
}
